export default {
  loading: false,
  listOfOutlets: [],
  getOrgStats: null,
  orgStructure: {
    ds: {
      collapsed: true,
      id: "1",
      name: "Lao Lao",
      title: "general manager",
      onleave: true,
      children: [
        { id: "2", name: "Bo Miao", title: "department manager" },
        {
          id: "3",
          name: "Su Miao",
          title: "department manager",
          children: [
            { id: "4", name: "Tie Hua", title: "senior engineer" },
            {
              id: "5",
              name: "Hei Hei",
              title: "senior engineer",
              children: [
                { id: "6", name: "Pang Pang", title: "engineer" },
                { id: "7", name: "Xiang Xiang", title: "UE engineer" }
              ]
            }
          ]
        }
      ]
    },
    dts: "",
    dtd: "",
    selectedNode: {
      id: null
    }
  },
  payScheduleSetting: {},
  // orgHierarchy: [],
  // orgLevels: [],
  functions: [],
  getAllFunctions: [],
  getAllDesignations: [],
  getAllPfa: [],
  orgProfile: {},
  industries: [
    {
      id: "1",
      name: "Automotive"
    },
    {
      id: "2",
      name: "Agriculture"
    },
    {
      id: "3",
      name: "Consulting and Professional Services"
    },
    {
      id: "4",
      name: "Consumer Goods"
    },
    {
      id: "5",
      name: "Education"
    },
    {
      id: "6",
      name: "Engineering and Construction"
    },
    {
      id: "7",
      name: "Financial Services"
    },
    {
      id: "8",
      name: "Government and Public Services"
    },
    {
      id: "9",
      name: "Healthcare"
    },
    {
      id: "10",
      name: "Hospitality, Recreation and Leisure"
    },
    {
      id: "11",
      name: "Industrial Goods"
    },
    {
      id: "12",
      name: "Information and Communications Technology"
    },
    {
      id: "13",
      name: "Manufacturing"
    },
    {
      id: "14",
      name: "Materials and Mining"
    },
    {
      id: "15",
      name: "Media and Creative"
    },
    {
      id: "16",
      name: "Non-Profit Organization"
    },
    {
      id: "17",
      name: "Petro-chemicals, Oil and Gas"
    },
    {
      id: "18",
      name: "Power and Utilities"
    },
    {
      id: "19",
      name: "Transportation"
    },
    {
      id: "20",
      name: "Real Estate"
    }
  ],
  rootUser: {},
  securityQuestionStatus: "",
  twoFactorStatus: "",
  activeSecurityQuestions: "",
  securityQuestions: [],
  allOrgUsers: [],
  paySchedule: {}
};
