/* eslint-disable import/prefer-default-export */
import {
  AxiosHumanar,
  $AuthUser
} from "./orgRepository";

export const managerSelfServiceAPI = {
  $_getManagerAnnualPlans(payload) {
    const url = `/leave-plans/manager/${this.$AuthUser.id}${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerLeaveCalendar(payload) {
    const url = `/leave-plans/manager/${this.$AuthUser.id}/calendar-view/${payload.leaveMonth}/${payload.leaveYear}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerLeaveDetails(leaveYear, params) {
    const url = `/leave-plans/manager/${this.$AuthUser.id}/detailed-view/${leaveYear}?${params}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeAnnualLeavePlanDetails({ planId, year, params }) {
    const url = `/leave-plans/manager/${this.$AuthUser.id}/detailed-view/${year}/${planId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getManagersEmployeeLeaveRequest(params) {
    const url = `/leave/manager/${this.$AuthUser.id}/employees-leave-requests`;
    return AxiosHumanar.get(url, { params });
  },
  $_getManagersLeaveAnalytics() {
    const url = `/leave/manager/${this.$AuthUser.id}/summary`;
    return AxiosHumanar.get(url);
  },
  $_getManagersEmployeesDayBack(params) {
    const url = `/leave/manager/${this.$AuthUser.id}/employees-leave-back-requests`;
    return AxiosHumanar.get(url, { params });
  },
  $_getManagersDayBackSummary() {
    const url = `/leave/manager/${this.$AuthUser.id}/employees-leave-back-requests/summary`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesAdvance(query) {
    const url = `/advances-and-loans/manager/${this.$AuthUser.id}/employees-advance?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesAdvanceSummary() {
    const url = `/advances-and-loans/manager/${this.$AuthUser.id}/employees-advance/summary`;
    return AxiosHumanar.get(url)
  },
  $_getManagerEmployeesLoan(query) {
    const url = `/advances-and-loans/manager/${this.$AuthUser.id}/employees-loan?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesLoanSummary() {
    const url = `/advances-and-loans/manager/${this.$AuthUser.id}/employees-loan/summary`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesOvertime(query) {
    const url = `/ess/overtime/manager/${this.$AuthUser.id}/planned-requests?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesOvertimePay(query) {
    const url = `/ess/overtime/manager/${this.$AuthUser.id}/pay-requests?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesPlannedWork(query) {
    const url = `/ess/overtime/manager/${this.$AuthUser.id}/planned-requests?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerPayRequestSummary() {
    const url = `/ess/overtime/manager/pay-requests/${this.$AuthUser.id}/summary`;
    return AxiosHumanar.get(url);
  },
  $_getManagerPlannedWorksSummary() {
    const url = `/ess/overtime/manager/planned-works/${$AuthUser.id}/summary`;
    return AxiosHumanar.get(url);
  },
  $_employeesConfirmation(payload) {
    const url = `/confirm/managers/${$AuthUser.id}?${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerConsolidatedAdvanceAndLoanForEmployees(query) {
    const url = `advances-and-loans/manager/${$AuthUser.id}/consolidated-employees-requests?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerConsolidatedAdvanceLoanSummary() {
    const url = `/advances-and-loans/manager/${$AuthUser.id}/consolidated-employees-requests/summary`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeesPromotion(query) {
    const url = `/promote/managers/${$AuthUser.id}?${query}`;
    return AxiosHumanar.get(url)
  },
  $_cancelEmployeesPromotion(payload) {
    const url = `/cancel/promote/${$AuthUser.id}`;
    return AxiosHumanar.patch(url, payload)
  },
  $_getManagersEmployeeDelegation(params) {
    const url = `delegation/managers/${$AuthUser.id}?${params}`;
    return AxiosHumanar.get(url)
  },
  $_cancelDelegation(id) {
    const url = `/employee-action/delegation-management/${id}`;
    return AxiosHumanar.get(url);
  },
  $_getManagersRedeployment(params) {
    const url = `/redeployment/managers/${$AuthUser.id}?${params}`;
    return AxiosHumanar.get(url);
  },
  $_cancelRedployment(payload) {
    const url = `/employee-action/redeployment/${payload}`;
    return AxiosHumanar.post(url);
  },
  $_getManagerSuspendedEmployees(queryParams) {
    const url = `/employees/manager/${$AuthUser.id}/suspended?${queryParams}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerDisengagedEmployees(queryParams) {
    const url = `/employees/manager/${$AuthUser.id}/disengaged?${queryParams}`;
    return AxiosHumanar.get(url);
  },
};
