<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    updateRootBtnColor(color){
      document.documentElement.style.setProperty('--dynamic-Background', color);
    },
  },

  computed: {
    ...mapState({
      subscriptionDetails: (state) => state.subscription,
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      btnColor: (state) => state.orgAppearance.orgAppearanceData.buttonColor,
    }),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },

  watch: {
    isIdle(value) {
      if (value && process.env.NODE_ENV === "production") {
        this.$toasted.info("Your session have expired due to inactivity", {
          duration: 6000,
        });
        this.$handleLogout();
      }
    },

    btnColor(newColor, oldColor){
        this.updateRootBtnColor(newColor || defaultBTNColor);
    }
  },


  async created() {
    this.$store.dispatch("org/getProfile");
    await this.$store.dispatch("getOrgAppearance");
  },
  mounted() {
    /* const getUser = JSON.parse(localStorage.getItem("linchpin"));
    const userId = getUser ? getUser.user.id : "";
    const socket = io(`${process.env.VUE_APP_NOTIFICATION}`, {
      query: {
        userId,
        app: "humanar",
      },
    });
    socket.on("getNotifications", (payload) => {
      this.$store.commit("SET_NOTIFICATIONS", payload);
    });
    socket.on("getUserNotifications", (payload) => {
      this.$store.commit("SET_NOTIFICATIONS", payload);
    }); */

    const newAccount = JSON.parse(window.localStorage.getItem("setNewAccount"));

    if (newAccount) {
      window.location.assign("/settings/profile");
      window.localStorage.removeItem("setNewAccount");
    }

    const syncedAccount = JSON.parse(window.localStorage.getItem("syncAccount"));

    if (syncedAccount) {
      window.location.assign("/admin/dashboard");
      window.localStorage.removeItem("syncAccount");
    }

    this.updateRootBtnColor(this.btnColor || this.defaultBTNColor);
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
</style>
