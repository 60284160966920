import subscription from "../middleware/isSubscribed";

export default [
    {
        path: "/documents",
        name: "OrganisationCompanyDocuments",
        component: () => import("@/modules/Admin/Documents/index"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
]
