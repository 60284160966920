/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, OrgId } from "./orgRepository";

export const compensationApi = {
  $_getCompensationRangeSpread() {
    const url = `/compensation-setting/range-spread/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_postCompensationRangeSpread(payload) {
    const url = "/compensation-setting/range-spread/add";
    return AxiosHumanar.post(url, payload);
  },
  $_getOrgPayGrades() {
    const url = `/compensation-setting/pay-grades/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgCompensationPayGrades(queryParams) {
    const url = `/compensation-setting/pay-grades/${OrgId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_postOrgPayGrades(payload) {
    const url = "/compensation-setting/pay-grade/add";
    return AxiosHumanar.post(url, payload);
  },
  $_findPayGradeById(paygradeId) {
    const url = `/compensation-setting/find-pay-grade/${paygradeId}`;
    return AxiosHumanar.get(url);
  },
  $_updatePayGradeById(payload) {
    const url = `/compensation-setting/pay-grade/update`;
    return AxiosHumanar.patch(url, payload);
  },
  $_deletePayGradeById(paygradeId) {
    const url = `/compensation-setting/pay-grade/delete/${paygradeId}`;
    return AxiosHumanar.delete(url);
  },
  $_getOrgReviewCycles() {
    const url = `/compensation-setting/review-cycles/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_postOrgReviewCycles(payload) {
    const url = "/compensation-setting/review-cycle/add";
    return AxiosHumanar.post(url, payload);
  },
  $_deleteOrgReviewCycles(reviewId) {
    const url = `/compensation-setting/review-cycle/delete/${reviewId}`;
    return AxiosHumanar.delete(url);
  },
  $_updateOrgReviewCycles(payload) {
    const url = `/compensation-setting/review-cycle/update`;
    return AxiosHumanar.patch(url, payload);
  },
  $_findOrgReviewCycleseById(cycleId) {
    const url = `/compensation-setting/find-review-cycle/${cycleId}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationPreferences() {
    const url = `/compensation-setting/compensation-preference/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_postCompensationPreferences(payload) {
    const url = "/compensation-setting/compensation-preference";
    return AxiosHumanar.post(url, payload);
  },
  $_getOrgPayInsights(queryParams) {
    const url = `compensation/pay-insight/${OrgId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_getPayGradePayInsightById({ paygradeId, queryParams }) {
    const url = `compensation/pay-insight/pay-grade/${paygradeId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_postAdjustPayGrade(payload) {
    const url = "/compensation/pay-insight/adjust-paygrade";
    return AxiosHumanar.post(url, payload);
  },
  $_getPayGradeRevisionHistory({ paygradeId, queryParams }) {
    const url = `compensation/pay-insight/pay-grade-review/history/${paygradeId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_getReferenceMarketRate({ levelId, policyPercent, currentMarketRate, referenceMarketRate }) {
    const url = `compensation-setting/get-calculation-field/${levelId}`;
    return AxiosHumanar.get(url, {params: {policyPercent, currentMarketRate, referenceMarketRate}});
  },
  $_getNotReferenceMarketRate({ levelId, midPoint, referenceMarketRate }) {
    const url = `compensation-setting/get-calculation-field/${levelId}`;
    return AxiosHumanar.get(url, {params: {midPoint, referenceMarketRate}});
  },
  $_getEmployeeCompensationSummary(params) {
    const url = `compensation/pay-insight/${params.paygradeId}/find-employee/${params.userId}`;
    return AxiosHumanar.get(url);
  },
  $_postDescretionaryAdjustment(payload) {
    const url = `compensation/pay-insight/discretional-adjustment`;
    return AxiosHumanar.post(url, payload);
  },
  $_getOrgCompensationReview() {
    const url = `compensation/compensation-review/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_createOrgCompensationReview(payload) {
    const url = `compensation/compensation-review`;
    return AxiosHumanar.post(url, payload);
  },
  $_updateOrgCompensationReview(payload) {
    const url = `compensation/compensation-review/update-review`;
    return AxiosHumanar.patch(url, payload);
  },
  $_deleteOrgCompensationReview(id) {
    const url = `compensation/compensation-review/delete-review/${id}`;
    return AxiosHumanar.delete(url);
  },
  $_compensationReviewTimelines(payload) {
    const url = `compensation/compensation-review/set-timeline`;
    return AxiosHumanar.post(url, payload);
  },
  $_getReviewTimelines(reviewId) {
    const url = `compensation/compensation-review/view-timeline/${reviewId}`;
    return AxiosHumanar.get(url);
  },
  $_compensationAwardLumpSum(payload) {
    const url = `compensation/compensation-review/award-lump-sum`;
    return AxiosHumanar.post(url, payload);
  },
  $_getCompensationReviewDetails({ reviewId, queryParams }) {
    const url = `compensation/compensation-review/details/${reviewId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_updateCompensationProposal(payload) {
    const url = `compensation/compensation-review/update-proposal`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getOrgAdjustmentProposals() {
    const url = `compensation/compensation-review/adjustment-proposal/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_postOrgAdjustmentProposals(payload) {
    const url = `compensation/compensation-review/adjustment-proposal`;
    return AxiosHumanar.post(url, payload);
  },
  $_getOrgMarketRateSettings(){
    const url = `compensation-setting/market-rate/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_compensationPromoteEmployee(payload){
    const url = `employee-action/promote`
    return AxiosHumanar.post(url, payload);
  },
  $_getCompensationPaygradeOverview(queryParams){
    const url = `compensation/paygrade-overview/${OrgId}`;
    return AxiosHumanar.get(url, {params: queryParams});
  },
  $_getCompensationReviewTypes(reviewAction){
    const url = `compensation/review-overview/type-details/${OrgId}?review_action=${reviewAction}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationReviewStatistics(){
    const url = `compensation/review-overview/review-statistics/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationCompaRatio({ queryParams }){
    const url = `compensation/payinsight-overview/${OrgId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_getCompensationReviewOverview(){
    const url = `compensation/review-overview/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerReviewDirectReports({ reviewId, authUserId, queryParams }){
    const url = `compensation/compensation-review/direct-report/${reviewId}/${authUserId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_findCompensationReviewById(reviewId){
    const url = `compensation/compensation-review/find-review/${reviewId}`;
    return AxiosHumanar.get(url);
  },
  $_submitCompensationDetailedReport(reviewId){
    const url = `compensation/compensation-review/submit-review/${reviewId}`
    return AxiosHumanar.patch(url);
  },
  $_postCompensationReviewForApproval(payload){
    const url = `compensation/compensation-review/submit-review`
    return AxiosHumanar.post(url, payload);
  },
  $_getPaygradeByOrgParameters(payload){
    const url = `compensation-setting/parameter-paygrade`;
    return AxiosHumanar.post(url, payload);
  },
  $_getEmployeePayInfo(userId){
    const url = `employees/pay-information/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationReviewSummary(){
    const url = `compensation/review-overview/review-summary/${OrgId}`;
    return AxiosHumanar.get(url);
  },
};
