import Vue from "vue";

const mutations = {
  changeSelectedSettingsCard(state, payload) {
    state.selectedSettingsCard = payload;
  },
  // ////////////////////////////////////////////
  // PUSH LOCALSTORAGE TO STATE
  // ////////////////////////////////////////////
  UPDATE_STATE_FROM_LOCALSTORAGE(state) {
    state.$simpleAuthHeader.headers.authorization =
      localStorage.getItem("token");
    state.token = localStorage.getItem("token");
    state.linchpin = JSON.parse(localStorage.getItem("linchpin"));
    // console.log("i got here", state.linchpin);
  },

  SET_APP_SUBSCRIPTION(state) {
    const linchpin = JSON.parse(localStorage.getItem("linchpin"));
    if (linchpin) {
      const orgApps = linchpin.orgApps.find((app) => app.appSlug === "humanar");
      if (orgApps && orgApps.subscription) {
        const { isActive, status, expiryDate } = orgApps.subscription;
        state.expiryDate = expiryDate;
        state.subscriptionStatus = status;
        state.subscriptionIsActive = isActive;
      }
    }
  },

  UPDATE_LOCALSTORAGE_WITH_LINCHPIN(state, payload) {
    localStorage.removeItem("linchpin");
    localStorage.setItem("linchpin", JSON.stringify(payload));
  },
  // SET_NOTIFICATION(state, payload) {
  //   state.notification = payload;
  // },
  SET_ID(state, payload) {
    state.id = payload;
  },
  // SET_PERSONAL(state, payload) {
  //   state["Personal Information"] = payload;
  // },
  SET_PRIVILEGES(state, payload) {
    state.privileges = payload;
  },
  SET_MODULE_NAME(state, payload) {
    state.moduleName = payload;
  },
  SET_EMPLOYEE(state, payload) {
    state.employeeDetails = payload;
  },
  SET_GROUP(state, payload) {
    state.Groups = payload;
  },
  SET_PAY_INFO(state, payload) {
    state["Pay Information"] = payload;
  },
  SET_APPLICATION(state, payload) {
    state.Applications = payload;
  },
  SET_DOCUMENT(state, payload) {
    state.Documents = payload;
  },
  GET_BANKS(state, payload) {
    state.Banks = payload;
  },
  GET_SUBSCRIPTION(state, payload) {
    state.subscription = payload;
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  UPDATE_STARRED_PAGE(state, payload) {
    const index = state.navbarSearchAndPinList.data.findIndex(
      (item) => item.index === payload.index
    );
    state.navbarSearchAndPinList.data[index].highlightAction = payload.val;
  },
  GENERATE_PAYSTACK_REFERENCE(state) {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    state.paystackReference = text;
  },
  UPDATE_APP_DEFAULT_API_RESOURCES(state, payload) {
    // console.log(payload)
    state.appDefaultApiResources = payload;
  },
  async UPDATE_APP_SETTINGS(state, payload) {
    state.appSettings = payload;
    await localStorage.setItem(
      "appSettings",
      JSON.stringify(payload.fixedPaySetup)
    );
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_AdvanceLoanloading(state, payload) {
    state.AdvanceLoanloading = payload;
  },
  SET_LEVELS(state, payload) {
    state.levels = payload;
  },
  SET_ADVANCE(state, payload) {
    state.salaryAdvances = payload;
  },
  SET_TOAST(state, { color, text }) {
    state.toast = true;
    state.toastText = text;
    switch (state.toastColor) {
      case color === "success":
        return "#009432";
      case color === "error":
        return "#EA2027";

      default:
        return color;
    }
  },
  UPDATE_TOAST(state, payload) {
    state.toast = payload;
  },
  SET_INDUSTRIES(state, payload) {
    state.industries = payload;
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  SET_ALL_NOTIFICATIONS(state, payload) {
    state.allNotifications = payload;
  },
  GET_ORG_APPEARANCE(state, payload) {
    state.orgAppearance = {
      ...state.orgAppearance,
      orgAppearanceData: payload,
    };
  },
  UPDATE_ORG_APPEARANCE(state, { item, type }) {
    switch (type) {
      case "Logo":
        Vue.set(state.orgAppearance.orgAppearanceData, "logoUrl", item);
        break;
      case "Banner":
        Vue.set(state.orgAppearance.orgAppearanceData, "bannerUrl", item);
        break;
      case "sideNav":
        Vue.set(state.orgAppearance.orgAppearanceData, "navigationColor", item);
        break;
      case "buttons":
        Vue.set(state.orgAppearance.orgAppearanceData, "buttonColor", item);
        break;
      default:
        return state;
    }
  },
};
export default mutations;
