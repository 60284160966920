/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, OrgId, $AuthUser } from "./orgRepository"

export const timeAttendanceApi = {
  $_getOrgTimeTracking(params){
    const url = `/time-tracking/${OrgId}/locations`;
    return AxiosHumanar.get(url, { params });
  },
  $_createTimeTracking(payload){
    const url = `/time-tracking/${OrgId}/locations`;
    return AxiosHumanar.post(url, payload);
  },
  $_updateTimeTracking(payload){
    const url = `/time-tracking/${OrgId}/locations/${payload.timeTrackId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_activateTimeTracking({ timeTrackId, status, ...rest }){
    const url = `/time-tracking/${OrgId}/locations/${timeTrackId}?status=${status}`;
    return AxiosHumanar.post(url, { ...rest });
  },
  $_getOrgClockInOut(params){
    const url = `/time-tracking/${OrgId}/clockings`;
    return AxiosHumanar.get(url, { params });
  },
  $_getEssClockInOut({ params, userId }){
    const url = `/time-tracking/ess/${userId}/clockings`;
    return AxiosHumanar.get(url, { params });
  },
  $_getOrgAbsence(params){
    const url = `time-tracking/${OrgId}/absence`;
    return AxiosHumanar.get(url, { params });
  },
  $_getEssAbsence({ params, userId }){
    const url = `time-tracking/${OrgId}/absence/ess/${userId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getOrgSchedule(params){
    const url = `time-tracking/${OrgId}/schedules`;
    return AxiosHumanar.get(url, { params });
  },
  $_getEmployeeSchedule(params) {
    const url = `time-tracking/ess/${$AuthUser.id}/schedules`;
    return AxiosHumanar.get(url, { params });
  },
  $_createScheduleAdmin(payload){
    const url = `time-tracking/${OrgId}/schedules`;
    return AxiosHumanar.post(url, payload);
  },
  $_createScheduleEmployee(payload) {
    const url = `time-tracking/ess/${$AuthUser.id}/schedules`;
    return AxiosHumanar.post(url, payload);
  },
  $_postEssClockInOut({ payload, userId }){
    const url = `/time-tracking/ess/${userId}/clockings`;
    return AxiosHumanar.post(url, payload);
  },
  $_deleteEssSchedule(scheduleId){
    const url = `time-tracking/ess/${$AuthUser.id}/schedules/${scheduleId}`;
    return AxiosHumanar.delete(url);
  },
  $_patchEssSchedule(payload, scheduleId){
    const url = `time-tracking/ess/${$AuthUser.id}/schedules/${scheduleId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_addEmployeesSchedule(payload, scheduleId) {
    const url = `time-tracking/${OrgId}/schedules/${scheduleId}`;
    return AxiosHumanar.post(url, payload)
  },
  $_addEmployeesScheduleESS(payload, scheduleId) {
    const url = `time-tracking/ess/${$AuthUser.id}/schedules/${scheduleId}`;
    return AxiosHumanar.post(url, payload)
  },
  $_getSettingTimeTracking(params){
    const url = `time-tracking/${OrgId}/settings`;
    return AxiosHumanar.get(url, { params });
  },
  $_postSettingTimeTracking(payload){
    const url = `time-tracking/${OrgId}/settings`;
    return AxiosHumanar.post(url, payload);
  },
  $_patchSettingTimeTracking(payload){
    const url = `time-tracking-clock-settings/${payload.settingId}`;
    return AxiosHumanar.put(url, payload);
  },
  $_setStatusSettingTimeTracking({ settingId, status }){
    const url = `/time-tracking-clock-settings/toggle/${settingId}`;
    return AxiosHumanar.put(url, { status });
  },
  $_deleteTimeTrackingLocation(locationId){
    const url = `time-tracking/${OrgId}/schedules/${locationId}`;
    return AxiosHumanar.delete(url);
  },
  $_deleteLocations(payload){
    const url = `/time-tracking/${OrgId}/locations/${payload}`;
    return AxiosHumanar.delete(url);
  },
}