import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/modules/Admin/SettingsModule"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
    children: [
      {
        path: "/settings/profile",
        name: "Profile",
        component: () => import("@/modules/Admin/SettingsModule/Profile"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/admins",
        name: "Admin",
        component: () =>
          import("@/modules/Admin/SettingsModule/UsersAndGroups"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/audit-logs",
        name: "AuditLog",
        component: () =>
          import("@/modules/Admin/SettingsModule/AuditLog/auditSettings"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/roles-privileges",
        name: "RolesAndPrivileges",
        component: () => import("@/modules/Admin/SettingsModule/RolesAndPre"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/performance",
        name: "PerformanceSettings",
        component: () => import("@/modules/Admin/SettingsModule/Performance"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/paysettings",
        name: "PaySettings",
        component: () => import("@/modules/Admin/SettingsModule/PaySettings"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/compensation",
        name: "CompensationSettings",
        component: () => import("@/modules/Admin/SettingsModule/Compensation"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/employee-management",
        name: "EmployeeManagementSettings",
        component: () =>
          import("@/modules/Admin/SettingsModule/EmployeeManagement"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/security",
        name: "SecuritySettings",
        component: () => import("@/modules/Admin/SettingsModule/Security"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/permissions",
        name: "Permissions",
        component: () => import("@/modules/Admin/SettingsModule/Permissions"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/leave-management",
        name: "LeaveManagementSettings",
        component: () => import("@/modules/Admin/SettingsModule/TimeOff"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/time-tracking",
        name: "TimeTrackingSettings",
        component: () => import("@/modules/Admin/SettingsModule/TimeTracking"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/appearance",
        name: "Appearance",
        component: () => import("@/modules/Admin/SettingsModule/Appearance"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/approval",
        name: "Approval",
        component: () => import("@/modules/Admin/SettingsModule/Approval"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/approval/work-flow/:actionId/:actionSlug",
        name: "ApprovalWorkFlow",
        component: () =>
          import("@/modules/Admin/SettingsModule/Approval/Workflow"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/bank-accounts",
        name: "BankAccounts",
        component: () => import("@/modules/Admin/SettingsModule/BankAccounts"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
      {
        path: "/settings/payments",
        name: "Payments",
        component: () => import("@/modules/Admin/SettingsModule/Payments"),
        meta: {
          authRequired: true,
          adminRequire: true,
          middleware: [subscription],
        },
      },
    ],
  },
  {
    path: "/settings/paysettings/earnings/new-earnings",
    name: "NewEarnings",
    component: () =>
      import("@/modules/Admin/SettingsModule/PaySettings/Earnings/NewEarnings"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/paysettings/earnings/edit-earnings/:id",
    name: "EditEarning",
    component: () =>
      import("@/modules/Admin/SettingsModule/PaySettings/Earnings/NewEarnings"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },

  {
    path: "/settings/paysettings/deductions/new-deduction",
    name: "NewDeduction",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/PaySettings/Deductions/NewDeduction"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },

  {
    path: "/settings/paysettings/deductions/edit-decuction/:id",
    name: "EditDeduction",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/PaySettings/Deductions/NewDeduction"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },

  {
    path: "/settings/performance/editrankings",
    name: "EditRankings",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/AppraisalScores/EditRankings"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },

  {
    path: "/settings/performance/appraisalcycles/create",
    name: "CreateCycles",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/AppraisalCycle/newCycle"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },

  {
    path: "/settings/performance/appraisalcycles/edit",
    name: "EditCycles",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/AppraisalCycle/newCycle"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },

  {
    path: "/settings/performance/edittemplate/:id",
    name: "EditTemplate",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/Template/TemplateSettings"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/performance/template-settings/:id",
    name: "TemplateSettings",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/Template/TemplateSettings"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/performance/templateusage",
    name: "TemplateUsage",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/Template/TemplateUsage"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/performance/moderationstructure/:id",
    name: "ModerationStructure",
    component: () =>
      import(
        "@/modules/Admin/SettingsModule/Performance/Moderation/ModerationStructure"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/performance/editpipcriteria",
    name: "EditPIPCriteria",
    component: () =>
      import("@/modules/Admin/SettingsModule/Performance/EditPIPCriteria"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/users-groups/create",
    name: "CreateUser",
    component: () =>
      import("@/modules/Admin/SettingsModule/UsersAndGroups/createUsers"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/users-groups/edit/:id",
    name: "EditUser",
    component: () =>
      import("@/modules/Admin/SettingsModule/UsersAndGroups/createUsers"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/users-groups/create/group",
    name: "CreateGroup",
    component: () =>
      import("@/modules/Admin/SettingsModule/UsersAndGroups/createGroup"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/users-groups/edit/group/:id",
    name: "EditGroup",
    component: () =>
      import("@/modules/Admin/SettingsModule/UsersAndGroups/createGroup"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/bank-accounts/transaction-history/:id",
    name: "Transaction History",
    component: () =>
      import("@/modules/Admin/SettingsModule/BankAccounts/TransactionHistory"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/roles-privileges/create/",
    name: "Create role",
    component: () =>
      import("@/modules/Admin/SettingsModule/RolesAndPre/createRole"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/roles-privileges/edit/:id",
    name: "Edit role",
    component: () =>
      import("@/modules/Admin/SettingsModule/RolesAndPre/editRole"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/approval/new/:actionId/:actionSlug",
    name: "NewWorkflow",
    component: () =>
      import("@/modules/Admin/SettingsModule/Approval/newApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/settings/approval/:workflowId",
    name: "ViewWorkflow",
    component: () =>
      import("@/modules/Admin/SettingsModule/Approval/newApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription],
    },
  },
  {
    path: "/admin/add-account",
    name: "New Account Setup",
    component: () => import("@/modules/Admin/SwitchAccount/NewAccount"),
    meta: {
      authRequired: true,
      adminRequire: true,
    },
  },
  {
    path: "/admin/sync-account",
    name: "Account Sync",
    component: () => import("@/modules/Admin/SwitchAccount/AccountSync"),
    meta: {
      authRequired: true,
      adminRequire: true,
    },
  },
];
