import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/approval/myrequests",
    name: "MyRequests",
    component: () => import("@/modules/Admin/Approval/Requests"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/myapprovals",
    name: "MyApprovals",
    component: () => import("@/modules/Admin/Approval/Approvals"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/advance&loan/:id",
    name: "requestForAdvance",
    component: () =>
      import("@/modules/Admin/Approval/Requests/extension/AdvanceApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/advance&loan/:id",
    name: "requestForLoan",
    component: () =>
      import("@/modules/Admin/Approval/Requests/extension/LoanApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/leave/:id",
    name: "requestForLeave",
    component: () =>
      import("@/modules/Admin/Approval/Requests/extension/LeaveApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/leave-back-request/:id",
    name: "requestForGetLeaveDay(s)Back",
    component: () =>
      import("@/modules/Admin/Approval/Requests/extension/DaysBackApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/promote/:id",
    name: "employeePromotion",
    component: () => import("@/modules/Admin/Approval/Requests/extension/PromotionApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/redeploy/:id",
    name: "employeeRedeployment",
    component: () => import("@/modules/Admin/Approval/Requests/extension/RedeploymentApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/delegate/:id",
    name: "employeeDelegationManagement",
    component: () => import("@/modules/Admin/Approval/Requests/extension/DelegationManagementApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/probation/:id",
    name: "employeeProbationExtension",
    component: () => import("@/modules/Admin/Approval/Requests/extension/ProbationApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/suspend/:id",
    name: "employeeSuspension",
    component: () => import("@/modules/Admin/Approval/Requests/extension/SuspensionApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/disengage/:id",
    name: "employeeDisengagement",
    component: () => import("@/modules/Admin/Approval/Requests/extension/DisengageApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/re-engage/:id",
    name: "re-engageEmployee",
    component: () => import("@/modules/Admin/Approval/Requests/extension/ReEngageApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/unsuspend/:id",
    name: "unsuspendEmployee",
    component: () => import("@/modules/Admin/Approval/Requests/extension/UnSuspendApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/revise-salary/:id",
    name: "reviseEmployeeSalary",
    component: () => import("@/modules/Admin/Approval/Requests/extension/ReviseSalaryApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/confirm-employee/:id",
    name: "employeeConfirmation",
    component: () => import("@/modules/Admin/Approval/Requests/extension/ConfirmationApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/approval/viewrequest/overtime-plannedwork/:id",
    name: "requestForOvertimeWork",
    component: () =>
      import(
        "@/modules/Admin/Approval/Requests/extension/OvertimePlannedWorkApproval"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/overtime-pay/:id",
    name: "requestForOvertimePay",
    component: () =>
      import("@/modules/Admin/Approval/Requests/extension/OvertimePayApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/payrun/:id",
    name: "approvePayRun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/ViewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/compensation-review/:id",
    name: "compensationReview",
    component: () => import("@/modules/Admin/Approval/Requests/extension/CompensationReviewApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/pay-adjustment-details/:id",
    name: "adjustEmployeeSalary",
    component: () => import("@/modules/Admin/Approval/Requests/extension/EmployeePayAdjustmentApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/compensation-proposal/:id",
    name: "compensationProposal",
    component: () => import("@/modules/Admin/Approval/Requests/extension/CompensationProposalApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/adjust-paygrade-individual/:id",
    name: "adjustPaygradeIndividual",
    component: () => import("@/modules/Admin/Approval/Requests/extension/AdjustPayGradeIndividualApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/approval/viewrequest/adjust-paygrade-organisation/:id",
    name: "adjustPayGrade(organization)",
    component: () => import("@/modules/Admin/Approval/Requests/extension/AdjustPayGradeOrganisationApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
];
