import { format,parse } from "date-fns";
import AppConfig from "../../AppConfig.json";
import NODE_ENV from "../../env";

const GlobalMixins = {
  computed: {
    $NODE_ENV() {
      return NODE_ENV;
    },
    $API_GATEWAY() {
      return AppConfig[this.$NODE_ENV].apiGateway;
    },
    $apiCloudenly() {
      return this.$store.state.$apiCloudenly;
    },
    $simpleAuthHeader() {
      return this.$store.state.$simpleAuthHeader;
    },
    $multiPartHeader() {
      return this.$store.state.$multiPartHeader;
    },
    $userData() {
      return this.$store.state.authCredentials;
    },
    $businessData() {
      return this.$store.state.business;
    },
    $profileSettings() {
      return this.$store.state.profileSettings;
    },
    $isAccountSetup() {
      return this.$store.state.isAccountSetup;
    },
    $DATEFORMAT() {
      return format;
    },
    $DATEPARSE(){
      return parse;
    },
    $subscriptionPlan(){
      if (this.$store.state.subscription) {
        return this.$store.state.subscription.plan
      } return null
    }
  },
  methods: {
    isNumeric(n) {
      return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
    },
    toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
    },
    validateParams(params) {
      if (!params || !params.length) return "";
      return params;
    },
    convertToCurrency(amount, decimal = 2) {
      if (amount) {
        return `₦${Number(amount)
          .toFixed(decimal)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      return "₦0.00";
    },
    hasSpecificModule(specificModule) {
      return (this.$store.state?.subscription?.susbcribeModules
        ?.map(m => m.name).includes(specificModule)
      )
    }
  }
};
export default GlobalMixins;
