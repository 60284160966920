import * as _ from "lodash"
import API from "@/services/apiFactory"


export default {

  setIsLoading({ commit }, payload){
    commit("SET_ISLOADING", payload)
  },

  async setMarketRateSettings({ commit, state }, payload) {
    commit("SET_ISLOADING", true)

    if(!_.isBoolean(payload)){
      await API.$_getOrgMarketRateSettings().then(({ data }) => {
        commit('SET_MARKET_RATE_SETTINGS', data.market.referenceMarketRate)

        if(_.isBoolean(data.market.referenceMarketRate)){
          const currentYear = new Date().getFullYear()
          const previousYear = new Date(data.market.updatedAt).getFullYear()

          if(_.lte(currentYear, previousYear)) commit('IS_SET_MARKET_RATE', true)
          else commit('IS_SET_MARKET_RATE', false)
        }
      }).catch((error) => {
        if(_.isNull(state.referenceMarketRate)){
          commit("SET_TOASTED", {type: 'error', message: error.message})
          setTimeout(() => commit("SET_TOASTED", {}), 3000)
        }
      })

    } else commit('SET_MARKET_RATE_SETTINGS', payload)
    commit("SET_ISLOADING", false)
  },
};
