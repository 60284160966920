/* eslint-disable import/prefer-default-export */
import { AxiosPaymentLive, AxiosHumanar, OrgId } from "./orgRepository";

export const paymentApi = {
  $_addDirectDebitAccount(payload) {
    const url = `/direct-debit/accounts`;
    return AxiosPaymentLive.post(url, payload);
  },
  $_getDefaultDirectDebitAccount() {
    const url = `/direct-debit/accounts/default/${OrgId}`;
    return AxiosPaymentLive.get(url);
  },
  $_getAllDirectDebitAccounts() {
    const url = `/direct-debit/accounts/list/${OrgId}`;
    return AxiosPaymentLive.get(url);
  },
  $_getOneDirectDebitAccount(accountId) {
    const url = `/direct-debit/accounts/${accountId}`;
    return AxiosPaymentLive.get(url);
  },
  $_deleteDirectDebitAccount(accountId) {
    const url = `/direct-debit/accounts/${accountId}`;
    return AxiosPaymentLive.delete(url);
  },

  $_addNubanAccount(payload) {
    const url = `/nuban/accounts`;
    return AxiosPaymentLive.post(url, payload);
  },

  $_addSweepAccount(payload) {
    const url = `/nuban/sweep/accounts`;
    return AxiosPaymentLive.post(url, payload);
  },

  $_getSweepAccount() {
    const url = `/nuban/org/sweep/accounts/${OrgId}`;
    return AxiosPaymentLive.get(url);
  },

  $_paySweepAccount(payload) {
    const url = `payroll/sweep/account/nuban-pay`;
    return AxiosHumanar.post(url, payload);
  },

  $_deleteSweepAccount(id) {
    const url = `/nuban/sweep/accounts/${id}`;
    return AxiosPaymentLive.delete(url)
  },

  $_getNubanAccount() {
    const url = `/nuban/accounts/default/${OrgId}`;
    return AxiosPaymentLive.get(url);
  },

  $_deleteNubanAccount(id) {
    const url = `/nuban/accounts/${id}`;
    return AxiosPaymentLive.delete(url);
  },

  $_updateNubanStatus(payload) {
    const url = `/nuban/accounts/status`;
    return AxiosPaymentLive.patch(url, payload);
  },
  $_nubanTransactionHistory({ nubanId }, params) {
    const url = `/nuban/accounts/${nubanId}/transactions${params}`;
    return AxiosPaymentLive.get(url);
  }
};
