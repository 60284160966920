const getters = {
  starredPages: (state) => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  appSettings: (state) => state.appSettings,
  humanarLocations: ({ locations }) => locations.map(({
    id, name, employeesCount, address, city, country, state,
  }) => ({
    id,
    name,
    employeesCount,
    address,
    city,
    country,
    state,
  })),
  humanarTaxs: ({ taxAuthorities }) => taxAuthorities.map((item) => ({
    id: item.id,
    name: item.name,
    code: item.code,
    state: item.state.name,
    label: 'PAYE',
    tin: item.tin ? item.tin.tin : '',
    tinId: item.tin ? item.tin.id : '',
    editable: false,
  })),
  humanarLocation: ({ location }) => {
    const {
      id, name, address, city, state, country, zipcode, longitude, latitude,
    } = location;
    return {
      id, name, address, city, state, country, zipcode, longitude, latitude,
    };
  },
  defaultLocation({ defaultLocation }) {
    return {
      id: defaultLocation.id,
      stateId: defaultLocation.stateId,
      address: defaultLocation.address,
      city: defaultLocation.city,
      state: defaultLocation.state,
      country: defaultLocation.country,
      latitude: defaultLocation.latitude,
      longitude: defaultLocation.longitude,
    };
  },
};

export default getters;
