const state = {
  irs: [
    {
      id: 1,
      name: 'Lagos state internal Revenue Service',
    },
  ],
  pencom: [
    {
      name: 'Apt pension fund managers',
      value: 1,
    },
    {
      name: 'ARM Pension Managers',
      value: 2,
    },
    {
      name: 'AXA Mansard Pensions Limited',
      value: 3,
    },
    {
      name: 'Certified Pension Institute of Nigeria',
      value: 4,
    },
    {
      name: 'Crusader Pensions Limited',
      value: 5,
    },
    {
      name: 'Fidelity Pensions Managers Limited',
      value: 6,
    },
    {
      name: 'First Guarantee Pension Limited',
      value: 7,
    },
    {
      name: 'Fug Pensions Limited',
      value: 8,
    },
    {
      name: 'Leadway Pensure PFA Limited',
      value: 9,
    },
    {
      name: 'Legacy Pension Managers Limited',
      value: 10,
    },
    {
      name: 'National Pension Commission',
      value: 11,
    },
    {
      name: 'NLPC Pension Fund Administrators',
      value: 12,
    },
    {
      name: 'Oak Pensions Limited',
      value: 13,
    },
    {
      name: 'Pensions Alliance Limited',
      value: 14,
    },
    {
      name: 'Premium Pension Limited',
      value: 15,
    },
    {
      name: 'Sigma Pensions',
      value: 16,
    },
    {
      name: 'Stanbic IBTC Pension Managers Limited',
      value: 17,
    },
    {
      name: 'Trustfund Pensions Plc',
      value: 18,
    },

  ],
  banks: [
    {
      name: 'Access Bank',
      slug: 'access-bank',
      code: '044',
      longcode: '044150149',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 1,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Citibank Nigeria',
      slug: 'citibank-nigeria',
      code: '023',
      longcode: '023150005',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 2,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Diamond Bank',
      slug: 'diamond-bank',
      code: '063',
      longcode: '063150162',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 3,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Ecobank Nigeria',
      slug: 'ecobank-nigeria',
      code: '050',
      longcode: '050150010',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 4,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Enterprise Bank',
      slug: 'enterprise-bank',
      code: '084',
      longcode: '084150015',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 5,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Fidelity Bank',
      slug: 'fidelity-bank',
      code: '070',
      longcode: '070150003',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 6,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'First Bank of Nigeria',
      slug: 'first-bank-of-nigeria',
      code: '011',
      longcode: '011151003',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 7,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'First City Monument Bank',
      slug: 'first-city-monument-bank',
      code: '214',
      longcode: '214150018',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 8,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Guaranty Trust Bank',
      slug: 'guaranty-trust-bank',
      code: '058',
      longcode: '058152036',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 9,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Heritage Bank',
      slug: 'heritage-bank',
      code: '030',
      longcode: '030159992',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 10,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Keystone Bank',
      slug: 'keystone-bank',
      code: '082',
      longcode: '082150017',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 11,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'MainStreet Bank',
      slug: 'mainstreet-bank',
      code: '014',
      longcode: '014150331',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 12,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Skye Bank',
      slug: 'skye-bank',
      code: '076',
      longcode: '076151006',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 13,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Stanbic IBTC Bank',
      slug: 'stanbic-ibtc-bank',
      code: '221',
      longcode: '221159522',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 14,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Standard Chartered Bank',
      slug: 'standard-chartered-bank',
      code: '068',
      longcode: '068150015',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 15,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Sterling Bank',
      slug: 'sterling-bank',
      code: '232',
      longcode: '232150016',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 16,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Union Bank of Nigeria',
      slug: 'union-bank-of-nigeria',
      code: '032',
      longcode: '032080474',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 17,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'United Bank For Africa',
      slug: 'united-bank-for-africa',
      code: '033',
      longcode: '033153513',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 18,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Unity Bank',
      slug: 'unity-bank',
      code: '215',
      longcode: '215154097',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 19,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Wema Bank',
      slug: 'wema-bank',
      code: '035',
      longcode: '035150103',
      gateway: 'etz',
      active: true,
      is_deleted: null,
      id: 20,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
    {
      name: 'Zenith Bank',
      slug: 'zenith-bank',
      code: '057',
      longcode: '057150013',
      gateway: '',
      active: true,
      is_deleted: null,
      id: 21,
      createdAt: '2016-07-14T10:04:29.000Z',
      updatedAt: '2016-07-14T10:04:29.000Z',
    },
  ],
};

const getters = {
  banks: (_state) => _state.banks.filter((x) => x.id),
  pencoms: (_state) => _state.pencom.filter((x) => x.name),
  irs: (_state) => _state.irs,
};

export default {
  state,
  getters,
};
