import state from './modulePerformanceState';
import mutations from './modulePerfornanceMutations';
import actions from './modulePerformanceActions';
import getters from './modulePerformanceGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
