import Vue from "vue";

Vue.directive("feature-request", el => {
  if (
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "staging"
  ) {
    // eslint-disable-next-line no-param-reassign
    el.style.display = "none";
  }
});
