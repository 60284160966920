import state from './moduleCompensationState';
import mutations from './moduleCompensationMutations';
import actions from './moduleCompensationActions';
import getters from './moduleCompensationGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
