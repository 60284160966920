import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/organisation/orgStructure",
    name: "orgStructureDesign",
    component: () =>
      import("@/modules/Admin/OrganisationModule/Function/orgStructure"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/organisation/functions",
    name: "Functions",
    component: () => import("@/modules/Admin/OrganisationModule/Function"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/organisation/designation",
    name: "designation",
    component: () => import("@/modules/Admin/OrganisationModule/Designation"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/organisation/levels",
    name: "levels",
    component: () => import("@/modules/Admin/OrganisationModule/Level"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/organisation/people",
    name: "OrganisationPeople",
    component: () => import("@/modules/Admin/OrganisationModule/People"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  }
];
