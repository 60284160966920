import state from './modulePensionState';
import mutations from './modulePensionMutations';
import actions from './modulePensionActions';
import getters from './modulePensionGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
