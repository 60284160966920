import AXIOS from "axios/index";
import apiFactory from "@/services/apiFactory";

export default {
  getAllMasterPfa({ commit }) {
    return apiFactory.$_getAllPfaMasterlist().then(resp => {
      // console.log(resp.data);
      commit("GET_ALL_PFA", resp.data);
    });
  },
  async getProfile({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getOrgProfile();
      commit("GET_ORG_PROFILE", data.org);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async updateProfile({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_updateOrgProfile(payload);

      commit(
        "SET_TOAST",
        { text: data.message, color: "#009432" },
        { root: true }
      );

      commit("SET_LOADING", false);

      return data;
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getRootUser({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getRootUser();
      commit("SET_ROOT_USER", data.user);
      commit("SET_LOADING", false);
      return data;
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async updateRootUser({ commit }, payload) {
    try {
      const { data } = await apiFactory.$_updateRootUser(payload);
      commit(
        "SET_TOAST",
        { text: "HR Admin successfully updated", color: "#009432" },
        { root: true }
      );
      return data;
    } catch (err) {
      throw new Error(err.response.data);
    }
  },
  async getSecurityQuestionStatus({ commit }) {
    try {
      const { data } = await apiFactory.$_getSecurityQuestionStatus();
      commit("SET_SECURITY_QUESTION_STATUS", data.isSetup);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getSecurityQuestions({ commit }) {
    try {
      const { data } = await apiFactory.$_getSecurityQuestion();
      commit("SET_SECURITY_QUESTIONS", data.questions);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getActiveSecurityQuestions({ commit }) {
    try {
      const { data } = await apiFactory.$_getActiveSecurityQuestion();
      commit("SET_ACTIVE_SECURITY_QUESTIONS", data.questions);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getTwoFactorStatus({ commit }) {
    try {
      const { data } = await apiFactory.$_getTwoFactorStatus();

      commit("SET_TWO_FACTOR_STATUS", data.isSetup);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async enableTwoFactor({ commit }, payload) {
    try {
      await apiFactory.$_twoFactorSetUp(payload);
      commit("SET_TWO_FACTOR_STATUS", true);
      commit(
        "SET_TOAST",
        { text: "Two factor authentication enabled", color: "#009432" },
        { root: true }
      );
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async disableTwoFactor({ commit }, payload) {
    try {
      await apiFactory.$_disableTwoFactor(payload);
      commit("SET_TWO_FACTOR_STATUS", false);
      commit(
        "SET_TOAST",
        { text: "Two factor authentication disabled", color: "#009432" },
        { root: true }
      );
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async sendTwoFactorCode(context, payload) {
    try {
      await apiFactory.$_sendTwoFactorCode(payload);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async sendAdminTwoFactorCode(context, payload) {
    try {
      await apiFactory.$_sendAdminTwoFactorCode(payload);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async transferAdminRights({ commit }, payload) {
    try {
      await apiFactory.$_transferAdminRights(payload);
    } catch (err) {
      commit(
        "SET_TOAST",
        { text: "An error occured", color: "error" },
        { root: true }
      );
      throw new Error(err.response);
    }
  },
  async getPaySchedules({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getPayScehdules();
      commit("SET_PAYSCHEDULE", data.paySchedule);
    } catch (err) {
      throw new Error(err.response);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async updatePaySchedule({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      commit(
        "SET_TOAST",
        { text: "Pay schedule successfully set", color: "success" },
        { root: true }
      );
      await apiFactory.$_updatePayScehdules(payload);
      commit("SET_PAYSCHEDULE", payload.paySchedule);
    } catch (err) {
      throw new Error(err.response);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getAllOrgUsers({ commit, payload }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getAllOrgUsers(payload);
      commit("SET_ALL_ORG_USERS", data);
    } catch (err) {
      throw new Error(err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  getOutlets({ commit, rootState }) {
    const orgId = rootState.linchpin.org.id;
    AXIOS.get(
      `${rootState.$API_GATEWAY_CORE}org/${orgId}/outlet`,
      rootState.$simpleAuthHeader
    ).then(res => {
      commit("UPDATE_OUTLET_LIST", res.data);
    });
  },
  orgStructureNodeSelected({ commit }, payload) {
    commit("ORG_STRUCTURE_CURRENT_NODE", payload);
  },
  getDefaultRoleSet({ commit, rootState }) {
    AXIOS.get(
      `${rootState.$API_GATEWAY}roles/default`,
      rootState.$simpleAuthHeader
    ).then(res => {
      commit("UPDATE_DEFAULT_ROLESET", res.data);
    });
  },
  getOrgHierarchiesLevel({ commit, rootState }) {
    const orgId = rootState.linchpin.org.id;
    AXIOS.get(
      `${rootState.$API_GATEWAY_CORE}org/${orgId}/functions/create/details`,
      rootState.$simpleAuthHeader
    ).then(res => {
      commit("ORG_FUNCTIONS", res.data);
    });
  },
  createFunction({ rootState }, payload) {
    const url = "org/functions";
    return AXIOS.post(
      rootState.$API_GATEWAY_CORE + url,
      payload,
      rootState.$simpleAuthHeader
    );
  },
  createDesignation({ rootState }, payload) {
    const url = "org/designations";
    return AXIOS.post(
      rootState.$API_GATEWAY_CORE + url,
      payload,
      rootState.$simpleAuthHeader
    );
  },
  updateDesignation({ rootState }, payload) {
    const url = "org/designations";
    return AXIOS.patch(
      rootState.$API_GATEWAY_CORE + url,
      payload,
      rootState.$simpleAuthHeader
    );
  },
  deleteDesignation({ rootState }, payload) {
    const url = "org/designations";
    return AXIOS.delete(rootState.$API_GATEWAY_CORE + url, {
      headers: rootState.$simpleAuthHeader,
      data: payload
    });
  },
  createLevel({ rootState }, payload) {
    const url = `org/${rootState.linchpin.org.id}/levels`;
    return AXIOS.post(
      rootState.$API_GATEWAY_CORE + url,
      payload,
      rootState.$simpleAuthHeader
    );
  },
  async getFunction({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getFunctionDesignations();
      commit("ORG_GET_FUNCTIONS", data.functions);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  getLevels({ rootState }) {
    const url = `org/${rootState.linchpin.org.id}/levels`;
    return AXIOS.get(
      rootState.$API_GATEWAY_CORE + url,
      rootState.$simpleAuthHeader
    );
  },
  async getDesignation({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getDesignations(payload);
      commit("ORG_GET_DESIGNATION", data.designations);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getOrgStats({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getOrgStats(payload);
      commit("ORG_STATS", data);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  }
};
