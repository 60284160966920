export default [
  {
    path: "/ess/view/personal-info",
    name: "EssPersonalInfo",
    component: () => import("@/modules/Ess/EmployeeInfo"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/view/directory",
    name: "EssDirectiory",
    component: () => import("@/modules/Ess/Directory"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/view/directory/detail/:id",
    name: "EssDirectioryDetails",
    component: () => import("@/modules/Ess/Directory/EmployeeDetails"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/view/payslip",
    name: "EssPayslip",
    component: () => import("@/modules/Ess/Payslip"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/view/payslip/:id",
    name: "ViewEssPayslip",
    component: () => import("@/modules/Ess/Payslip/viewPayslip"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/dashboard",
    name: "EmployeeDashboard",
    component: () => import("@/modules/Ess/Dashboard"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/groups",
    name: "EssGroups",
    component: () => import("@/modules/Ess/Groups"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  }
];
