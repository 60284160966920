import {
  parseISO,
  isAfter,
  getTime,
  formatDuration,
  intervalToDuration
} from "date-fns";

const getters = {
  starredPages: state =>
    state.navbarSearchAndPinList.data.filter(page => page.highlightAction),
  appSettings: state => state.appSettings,
  subscriptionTimer: state => {
    if (state.expiryDate) {
      const duration = intervalToDuration({
        start: new Date(),
        end: parseISO(state.expiryDate)
      });
      return formatDuration(duration, {
        delimiter: ", "
      });
    }
    return null;
  },
  hasSubscriptionExpired: state => id => {
    if (state.expiryDate || id) {
      const todayTime = getTime(new Date());
      const nextTime = getTime(new Date(state.expiryDate));
      const checkResult = isAfter(todayTime, nextTime);
      if (checkResult) {
        return true;
      }
    }
    return false;
  }
};

export default getters;
