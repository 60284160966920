
export default {
  UPDATE_ORG_USERS_STATE(state, payload) {
    state.lisOfAllUsers = payload;
  },
  USER__INVITE(state, payload) {
    state.listAllInvite = payload;
  },
  SET_ALL_EMPLOYEE_DETAILS(state, payload) {
    state.allEmployeeDetails = payload;
  },
  SET_EMPLOYEE_EMPLOYMENT_INFO(state, payload) {
    state.employeeEmplomentInfo = payload;
  },
  SET_EMPLOYEE_PERSONAL_INFO(state, payload) {
    state.employeePersonalInfo = payload;
  },
  SET_EMPLOYEE_PAYMENT_INFO(state, payload) {
    state.employeePaymentInfo = payload;
  },
  SET_EMPLOYEES(state, payload) {
    state.employees = payload;
  },
  SET_PROBATION_EMPLOYEES(state, payload) {
    state.probationEmployees = payload;
  },
  SET_METADATA(state, payload) {
    state.metaData = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_EMPLOYMENT_DETAILS(state, payload) {
    state.employmentDetails = payload;
  },
  SET_EMPLOYEE_ANNOUNCEMENTS(state, payload) {
    state.employeeAnnouncements = payload;
  },
  SET_ESS_PERMISSIONS(state, payload) {
    state.essPermissions = payload;
  },
  SET_EMPLOYEE_ANNOUNCEMENT(state, payload) {
    state.employeeAnnouncement = payload;
  },
  SET_EMPLOYEE_ANNOUNCEMENT_CATEGORIES(state, payload) {
    state.employeeAnnouncementCategories = payload;
  },
  SET_EMPLOYEE_ANNOUNCEMENT_RECIPIENTS(state, payload) {
    state.employeeAnnouncementRecipients = payload;
  },
  ADD_NEW_EMPLOYEE_ANNOUNCEMENT(state, payload) {
    state.employeeAnnouncements = [payload, ...state.employeeAnnouncements];
  },
  ADD_NEW_EMPLOYEE_ANNOUNCEMENT_CATEGORY(state, payload) {
    state.employeeAnnouncementCategories = [...payload];
  },
  UPDATE_EMPLOYEE_PHOTO(state, payload) {
    state.employeePhoto = payload;
  }
};
