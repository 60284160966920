export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload;
  },
  SET_LOCATION(state, payload) {
    state.location = payload;
  },
  SET_TAX_AUTHORITIES(state, payload) {
    state.taxAuthorities = payload;
  },
  SET_DEFAULT_OFFICE_LOCATION(state, payload) {
    state.defaultLocation = payload;
  },
  ADD_TAX_AUTHORITY_TIN(state, payload) {
    const taxAuthorityIndex = state.taxAuthorities.findIndex(
      (item) => item.id === payload.taxAuthorityId,
    );
    state.taxAuthorities[taxAuthorityIndex].tin = {
      id: payload.id,
      tin: payload.tin,
    };
  },
  REMOVE_TAX_AUTHORITY_TIN(state, payload) {
    payload.forEach((id) => {
      const taxAuthorityIndex = state.taxAuthorities.findIndex((item) => item.tin?.id === id);
      state.taxAuthorities[taxAuthorityIndex].tin = null;
    });
  },
};
