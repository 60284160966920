export default {
  SET_ISLOADING(state, payload) {
    state.isLoading = payload
  },
  SET_TOASTED(state, payload){
    state.toasted = payload
  },
  IS_SET_MARKET_RATE(state, payload){
    state.isSetMarketRate = payload
  },
  SET_MARKET_RATE_SETTINGS(state, payload){
    state.referenceMarketRate = payload
  }
};
