/* eslint-disable import/prefer-default-export */
import { AxiosAAA, AxiosAPP, AxiosHumanar, OrgId } from './orgRepository';
//
export const ApprovalApi = {
  $_getAllApprovalFlow(payload) {
    const url = `/approval/all/${OrgId}?module=Purchase&submodule=Purchase Requisitions`;
    return AxiosAAA.post(url, payload);
  },
  $_createManualApprovalFlow(payload) {
    const url = `/approval/create`;
    return AxiosAAA.post(url, payload);
  },
  $_getReportingLineFunction(payload) {
    const url = `org/reportline/functions`;
    return AxiosAAA.post(url, payload);
  },
  $_getReportingLineRoles(payload) {
    const url = `org/reportline/designations`;
    return AxiosAAA.post(url, payload);
  },
  $_getMyRequests(params, module) {
    const url = `/myrequests/${params}${module}`;
    return AxiosAPP.get(url);
  },
  $_getMyApprovalsfilter(params,userId, paginate, module) {
    const url = `/myapprovals/${userId}?filter=${params}${paginate || ''}${module}`;
    return AxiosAPP.get(url);
  },
  $_getMyApprovalRequests({ userId, query }) {
    const url = `/myapprovals/${userId}`;
    return AxiosAPP.get(url, {params: { ...query}});
  },
  $_getApprovalHistory(userId, params, module) {
    const url = `/history/${userId}${params}${module}`;
    return AxiosAPP.get(url);
  },
  $_getApprovalProgress(requestId) {
    const url = `/progress/${requestId}`;
    return AxiosAPP.get(url);
  },
  $_getOrgApprovalHistory({ userId, params }) {
    const url = `/history/${userId}`;
    return AxiosAPP.get(url, { params });
  },
  $_respondApprovalRequest(payload) {
    const url = `/approve/request`;
    return AxiosHumanar.post(url, payload);
  },
  $_getApprovalNumbers(params,userId) {
    const url = `/myapprovalsdetails/${userId}?filter=${params}`;
    return AxiosAPP.get(url);
  },
  $_getRequestNumbers(userId) {
    const url = `/myapprovalsdetails/${userId}`;
    return AxiosAPP.get(url);
  },
  $_getLeaveApprovalDetails(requestId) {
    const url = `/leave/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeePromotionDetails(requestId) {
    const url = `/promote/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeReDeployDetails(requestId) {
    const url = `/redeployed/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeSuspensionDetails(requestId) {
    const url = `/suspend/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeUnsuspendDetails(requestId) {
    const url = `/unsuspend/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeDisengageDetails(requestId) {
    const url = `/disengage/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeReengageDetails(requestId) {
    const url = `/reengage/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeConfirmationDetails(requestId) {
    const url = `/confirm/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeReviseSalaryDetails(requestId) {
    const url = `/revisesalary/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeProbationDetails(requestId) {
    const url = `/extend/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_extendEmployeeProbation(payload) {
    const url = `/update/extend-period`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getAdvanceApprovalDetails(requestId) {
    const url = `/advances-and-loans/advance-request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getLoanApprovalDetails(requestId) {
    const url = `/advances-and-loans/loan-request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimeApproval(requestId) {
    const url = `/overtime/request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePayApproval(requestId) {
    const url = `/overtime/pay-request/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeDelegationDetails(requestId) {
    const url = `/delegated/employee/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationProposalById(proposalId){
    const url = `/compensation/proposal/detail/${proposalId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployePayAdjustments(adjustmentId){
    const url = `/compensation/discretionary/detail/${adjustmentId}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationReviewApproval(requestId){
    const url = `compensation/request/detail/${OrgId}/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationPaygradeApproval(requestId){
    const url = `paygrade-review/details/${requestId}`;
    return AxiosHumanar.get(url);
  },
  $_getApprovalFlowApprovers(actionId){
    const url = `approvers/${OrgId}/${actionId}`;
    return AxiosHumanar.get(url);
  }
};
