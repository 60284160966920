import apiFactory from '@/services/apiFactory';

export default {
  async getHumanarLocations({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await apiFactory.$_getHumanarLocations();
      commit('SET_LOCATIONS', data.outlets);
      commit('SET_LOADING', false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getOneHumanarLocations({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data } = await apiFactory.$_getOneHumanarLocations(payload);
      commit('SET_LOCATION', data.outlet);
      commit('SET_LOADING', false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getHumanarTax({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await apiFactory.$_getOrgTaxAuthorityTins();
      commit('SET_TAX_AUTHORITIES', data.taxAuthorities);
      commit('SET_LOADING', false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async addLocation({ commit, dispatch }, payload) {
    try {
      commit('SET_LOADING', true);
      await apiFactory.$_addOutlet(payload);
      await dispatch('getHumanarLocations');
      commit('SET_LOADING', false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async addTax({ commit }, payload) {
    try {
      const { data } = await apiFactory.$_addOrgTaxAuthorityTin(payload);
      commit('SET_TOAST', { text: 'Successfully added TIN', color: 'success' }, { root: true });
      commit('ADD_TAX_AUTHORITY_TIN', { ...payload, ...data });
    } catch (err) {
      commit('SET_TOAST', { text: 'An Error Occured', color: 'error' }, { root: true });
    }
  },
  async deleteLocation({ dispatch }, payload) {
    try {
      await apiFactory.$_deleteOutlet(payload);
      await dispatch('getHumanarLocations');
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async deleteTax({ commit, state }, payload) {
    const previousState = [...state.taxAuthorities];
    try {
      commit('REMOVE_TAX_AUTHORITY_TIN', payload);
      commit('SET_TOAST', { text: 'Successfully removed TIN', color: 'success' }, { root: true });
      await apiFactory.$_deleteTaxAuthorityTin(payload);
    } catch (err) {
      commit(
        'SET_TOAST',
        { text: 'An Error occured reverting changes', color: 'error' },
        { root: true },
      );
      commit('SET_TAX_AUTHORITIES', previousState);
      throw new Error(err.response);
    }
  },
  async updateLocation({ commit }, payload) {
    try {
      await apiFactory.$_updateOutlet(payload);
      commit(
        'SET_TOAST',
        { text: 'Successfully updated location', color: 'success' },
        { root: true },
      );
    } catch (err) {
      commit('SET_TOAST', { text: 'An Error Occured', color: 'error' }, { root: true });
      throw new Error(err.response);
    }
  },
  async getDefaultLocation({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await apiFactory.$_getDefaultOfficeLocation();
      commit('SET_DEFAULT_OFFICE_LOCATION', data.office);
      commit('SET_LOADING', false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
};
