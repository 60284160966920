/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, OrgId } from "./orgRepository"

export const analyticsApi = {
  $_getOrgLocationDistribution({ locationId }){
    const url = `analytics/location-distribution/${OrgId}`;
    return AxiosHumanar.get(url, {params: { locationId }});
  },
  $_getOrgFunctionDistribution({ functionId }){
    const url = `analytics/function-distribution/${OrgId}`;
    return AxiosHumanar.get(url, {params: { functionId }});
  },
  $_getOrgFunctionsDistribution(params){
    const url = `analytics/function-distribution/${OrgId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getOrgDesignationDistribution(params){
    const url = `analytics/designation-distribution/${OrgId}${params}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgGenderDistribution({ functionId }){
    const url = `analytics/gender-distribution/${OrgId}`;
    return AxiosHumanar.get(url, {params: { functionId }});
  },
  $_getOrgEmploymentDistribution(queryParams){
    const url = `analytics/employment-distribution/${OrgId}`;
    return AxiosHumanar.get(url, {params: queryParams});
  },
  $_getOrgEmployeeStatusDistribution(){
    const url = `analytics/employee-status-distribution/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgAdminDistribution(){
    const url = `analytics/admin-distribution/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgEmploymentStatusDistribution({ functionId }){
    const url = `analytics/employment-status-distribution/${OrgId}`;
    return AxiosHumanar.get(url, {params: { functionId }});
  },
  $_getOrgPayTypeDistribution(){
    const url = `analytics/pay-types-distribution/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrganisationHierarchy(){
    const url = `analytics/hierarchies/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgJobLevelDistribution(){
    const url = `analytics/joblevel-distribution/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getPayItemsAnalytics(query) {
    const url = `analytics/payitems/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPayTypeAnalytics(query) {
    const url = `analytics/payroll-type/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPayCostAnalytics(query) {
    const url = `analytics/payroll-cost-distribution/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPayGenderGapAnalytics(query) {
    const url = `analytics/gender-pay-gap/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getOffCyclePayAnalytics(query) {
    const url = `analytics/off-cycle-pay/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getCompensationSalaryMetrics(queryParams){
    const url = `analytics/salary-metrics-paygrade/${OrgId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_getCompensationRatioMetrics(queryParams){
    const url = `analytics/salary-metrics-compa-ratio/${OrgId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_getCompensationCostTrends(queryParams){
    const url = `analytics/cost-trend/${OrgId}`;
    return AxiosHumanar.get(url, {params: {...queryParams}});
  },
  $_getDaysBackRequest({ leaveBackId, params }){
    const url = `leave/leave-back-requests/details/${leaveBackId}`;
    return AxiosHumanar.get(url, { params });
  }
}