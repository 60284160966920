import shortId from 'shortid';
import apiFactory from '@/services/apiFactory';

export default {
  async getPFAMasterList({ commit }) {
    try {
      const { data } = await apiFactory.$_getAllPfaMasterlist();
      commit('SET_PFA_MASTER_LIST', data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getPFAOrgList({ commit }) {
    try {
      const { data } = await apiFactory.$_getAllPfaOrglist();
      commit('SET_PFA_ORG_LIST', data.payload);
    } catch (err) {
      throw new Error(err.response.data);
    }
  },
  async getOrgPensionSettings({ commit }) {
    try {
      const { data } = await apiFactory.$_getOrgPensionSettings();
      commit('SET_ORG_PENSION_SETTINGS', data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async addOrgPFA({ commit }, payload) {
    try {
      // const orgPFA =
      const uniqueId = shortId.generate();
      commit('ADD_ORG_PFA', { id: uniqueId, ...payload });
      commit('SET_TOAST', { text: 'successfully added PFA', color: 'success' }, { root: true });
      const { data } = await apiFactory.$_addPfa(payload);
      commit('UPDATE_ORG_PFA_ID', { oldId: uniqueId, newId: data.payload.id });
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async updateOrgPFA({ commit }, payload) {
    try {
      commit('REMOVE_ORG_PFA', payload);
      await apiFactory.$_editPfa(payload);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async deleteOrgPFA({ commit }, payload) {
    try {
      commit('SET_TOAST', { text: 'successfully deleted PFA', color: 'success' }, { root: true });
      commit('REMOVE_ORG_PFA', payload);
      await apiFactory.$_deletePfa(payload);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async savePensionSettings({ commit }, payload) {
    try {
      commit(
        'SET_TOAST',
        { text: 'successfully updated pension settings', color: 'success' },
        { root: true },
      );
      await apiFactory.$_createPensionSettings(payload);
    } catch (err) {
      throw new Error(err.response);
    }
  },
};
