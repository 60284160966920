export default {
  SET_PFA_ORG_LIST(state, payload) {
    state.PFAOrgList = payload;
  },
  SET_ORG_PENSION_SETTINGS(state, payload) {
    state.PFAOrgSettings = payload;
  },
  SET_PFA_MASTER_LIST(state, payload) {
    state.PFAMasterList = payload;
  },
  UPDATE_ORG_PFA_ID(state, payload) {
    const PFAIndex = state.PFAOrgList.findIndex((item) => item.id === payload.oldId);
    state.PFAOrgList[PFAIndex].id = payload.newId;
  },
  ADD_ORG_PFA(state, payload) {
    // payload.pfaId;
    const orgPFA = state.PFAMasterList.find((item) => item.id === payload.pfaId);
    state.PFAOrgList.push({ pfaName: orgPFA.name, ...payload });
  },
  REMOVE_ORG_PFA(state, payload) {
    state.PFAOrgList = state.PFAOrgList.filter((item) => item.id !== payload);
  },
};
