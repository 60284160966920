export default [
  {
    path: "/ess/approval/myrequests",
    name: "EssMyRequests",
    component: () => import("@/modules/Ess/Approval/Requests"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/approval/myapprovals",
    name: "EssMyApprovals",
    component: () => import("@/modules/Ess/Approval/Approvals"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/approval/viewrequest/advance&loan/:id",
    name: "EssrequestForAdvance",
    component: () => import("@/modules/Ess/Approval/Requests/extension/AdvanceApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/advance&loan/:id",
    name: "EssrequestForLoan",
    component: () => import("@/modules/Ess/Approval/Requests/extension/LoanApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/leave/:id",
    name: "EssrequestForLeave",
    component: () => import("@/modules/Ess/Approval/Requests/extension/LeaveApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "ess/approval/viewrequest/leave-back-request/:id",
    name: "EssrequestForGetLeaveDay(s)Back",
    component: () =>
      import("@/modules/Admin/Approval/Requests/extension/DaysBackApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/approval/viewrequest/overtime-plannedwork/:id",
    name: "EssrequestForOvertimeWork",
    component: () => import("@/modules/Ess/Approval/Requests/extension/OvertimePlannedWorkApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/overtime-pay/:id",
    name: "EssrequestForOvertimePay",
    component: () => import("@/modules/Ess/Approval/Requests/extension/OvertimePayApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/promote/:id",
    name: "EssemployeePromotion",
    component: () => import("@/modules/Ess/Approval/Requests/extension/PromotionApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/redeploy/:id",
    name: "EssemployeeRedeployment",
    component: () => import("@/modules/Ess/Approval/Requests/extension/RedeploymentApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/suspend/:id",
    name: "EssemployeeSuspension",
    component: () => import("@/modules/Ess/Approval/Requests/extension/SuspensionApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/disengage/:id",
    name: "EssemployeeDisengagement",
    component: () => import("@/modules/Ess/Approval/Requests/extension/DisengageApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/re-engage/:id",
    name: "Essre-engageEmployee",
    component: () => import("@/modules/Ess/Approval/Requests/extension/ReEngageApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/unsuspend/:id",
    name: "EssunsuspendEmployee",
    component: () => import("@/modules/Ess/Approval/Requests/extension/UnSuspendApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/revise-salary/:id",
    name: "EssreviseEmployeeSalary",
    component: () => import("@/modules/Ess/Approval/Requests/extension/ReviseSalaryApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/confirm-employee/:id",
    name: "EssemployeeConfirmation",
    component: () => import("@/modules/Ess/Approval/Requests/extension/ConfirmationApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/payrun/:id",
    name: "EssapprovePayRun",
    component: () => import('@/modules/Ess/Approval/Requests/extension/PayRunApproval'),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/delegate/:id",
    name: "EssemployeeDelegationManagement",
    component: () => import("@/modules/Ess/Approval/Requests/extension/DelegationManagementApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/ess/approval/viewrequest/compensation-review/:id",
    name: "EsscompensationReview",
    component: () => import("@/modules/Ess/Approval/Requests/extension/CompensationReviewApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
    }
  },
  {
    path: "/approval/viewrequest/probation/:id",
    name: "EssemployeeProbationExtension",
    component: () => import("@/modules/Ess/Approval/Requests/extension/ProbationApproval"),
    meta: {
      authRequired: true,
      employeeUrl: true
    },
  },
  {
    path: "/approval/viewrequest/compensation-proposal/:id",
    name: "EsscompensationProposal",
    component: () => import("@/modules/Ess/Approval/Requests/extension/CompensationProposalApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
    }
  },
  {
    path: "/approval/viewrequest/pay-adjustment-details/:id",
    name: "EssadjustEmployeeSalary",
    component: () => import("@/modules/Ess/Approval/Requests/extension/EmployeePayAdjustmentApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
    }
  },
  {
    path: "/approval/viewrequest/adjust-paygrade-individual/:id",
    name: "EssadjustPaygradeIndividual",
    component: () => import("@/modules/Ess/Approval/Requests/extension/AdjustPayGradeIndividualApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
    }
  },
  {
    path: "/approval/viewrequest/adjust-paygrade-organisation/:id",
    name: "EssadjustPayGrade(organization)",
    component: () => import("@/modules/Ess/Approval/Requests/extension/AdjustPayGradeOrganisationApproval"),
    meta: {
      authRequired: true,
      adminRequire: true,
    }
  }
];
