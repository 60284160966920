export default {
  lisOfAllUsers: [],
  listAllInvite: [],
  allEmployeeDetails: [],
  employees: [],
  probationEmployees: [],
  essPermissions: {},
  metaData: {},
  loading: false,
  employeeEmplomentInfo: {},
  employeePersonalInfo: {},
  employeePaymentInfo: {},
  employmentDetails: {},
  employeeAnnouncements: [],
  employeeAnnouncement: {},
  employeeAnnouncementCategories: [],
  employeeAnnouncementRecipients: [],
  employeePhoto: null
};