// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VueHtmlToPaper from "vue-html-to-paper";
import Toasted from "vue-toasted";
import VTooltip from "v-tooltip";
import IdleVue from "idle-vue";
import apiFactory from "@/services/apiFactory";
import UtilityService from "@/utilities/utilityService";
import AppMixins from "./utilities/appMixin";
import store from "./store";
import App from "./App";
import Loader from "./components/Loader";
import Icon from "./components/Icon";
import router from "./router";
import "tailwindcss/tailwind.css";
import "@/assets/css/main.scss";

Vue.use(Toasted, {
  action: {
    text: "Cancel",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
Vue.use(VTooltip);

Vue.component("Loader", Loader);
Vue.component("Icon", Icon);
Vue.config.productionTip = false;

Vue.use(IdleVue, {
  store,
  idleTime: 600000,
  startAtIdle: false,
});


Vue.use(UtilityService);
require("./directives");

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API,
    libraries: "places", // necessary for places input
  },
});
const printOptions = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css",
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title,
};
Vue.use(VueHtmlToPaper, printOptions);
Vue.mixin(AppMixins);
Vue.mixin({
  methods: {
    ...apiFactory,
  },
});
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
