/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, OrgId } from "./orgRepository";

export const advanceloanApi = {
  $_applyForAdavanceAndLoan(payload) {
    const url = "/advances-and-loans/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_adavanceAndLoanApprovalByEmployeeId(payload) {
    const url = "advances-and-loans/employees-requests-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_aproveAdavanceByRequestId(payload) {
    const url = "advances-and-loans/requests-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_getEmployeeId({ id }) {
    const url = `employees/${id}?attributes=id,userId,grossPay`;
    return AxiosHumanar.get(url);
  },
  $_getAdminLoanAdvanceAnalytics({ type }) {
    const url = `/advances-and-loans/admins/${OrgId}/dashboard?type=${type}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminConsolidatedAnalytics() {
    const url = `/advances-and-loans/admins/${OrgId}/consolidated-dashboard`;
    return AxiosHumanar.get(url);
  },
  $_getAdminConsolidatedRequest() {
    const url = `/advances-and-loans/admins/${OrgId}/consolided-employees-requests`;
    return AxiosHumanar.get(url);
  },

  $_getAdminEmployeesAdvance(query) {
    const url = `/advances-and-loans/all-organization-advance/${OrgId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminEmployeesAdvanceStats(query= '') {
    const url = `/advances-and-loans/advance-request-statistics/${OrgId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminEmployeesLoan(query) {
    const url = `/advances-and-loans/all-organization-loan/${OrgId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminEmployeesLoanStats(query= '') {
    const url = `/advances-and-loans/loan-request-statistics/${OrgId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeAdvancePaySettings({ id, type }) {
    const url = `org/employees/${id}/employee-paysettings?payItemSlug=${type}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeAdvanceAndLoan({ id, query }) {
    const url = `/advances-and-loans/requests/employees/${id}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeAdvancePending({ id }) {
    const url = `/advances-and-loans/requests/employees/${id}?approvalStatus=pending&type=advance`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeLoanPending({ id }) {
    const url = `/advances-and-loans/requests/employees/${id}?approvalStatus=pending&type=loan`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeConsolidatedAdvanceAndLoan({ id }) {
    const url = `/advances-and-loans/consolidated-requests/employees/${id}`;
    return AxiosHumanar.get(url);
  },

  $_getManagerAdvanceAndLoanSummary({ id, type }) {
    const url = `/advances-and-loans/manager/${id}/dashboard?type=${type}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerEmployeeAdvanceAndLoanRequest({ id, type, status }) {
    const url = `/advances-and-loans/manager/${id}/employees-requests?type=${type}&approvalStatus=${status}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerConsolidatedAdvanceAndLoanDashboard({ id }) {
    const url = `/advances-and-loans/manager/${id}/consolidated-dashboard`;
    return AxiosHumanar.get(url);
  },
  $_getManagerConsolidatedAdvanceAndLoanForEmployees({ id }) {
    const url = `/advances-and-loans/manager/${id}/consolided-employees-requests`;
    return AxiosHumanar.get(url);
  },
  $_getAdvanceAndLoanApproval() {
    const url = "/leave/requests-approval";
    return AxiosHumanar.get(url);
  },
  $_getAdvanceAndLoanRequest(id) {
    const url = `/advances-and-loans/requests/${id}`;
    return AxiosHumanar.get(url);
  }
};
