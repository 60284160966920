import AppConfig from "../../AppConfig.json";
import NODE_ENV from "../../env";

const state = {
  selectedSettingsCard: "",
  appDefaultApiResources: {
    payrollRateTypes: [
      {
        label: "hello",
      },
    ],
  },
  appSettings: {},
  salaryAdvances: [],
  showModal: true,
  paystackReference: "",
  subscription: null,
  token: "",
  Banks: [],
  allNotifications: [],
  employeeDetails: {},
  linchpin: "",
  expiryDate: "",
  subscriptionStatus: "",
  subscriptionIsActive: null,
  notifications: [],
  privileges: "",
  moduleName: "",
  $API_GATEWAY: AppConfig[NODE_ENV].apiGateway,
  $API_GATEWAY_CORE: AppConfig[NODE_ENV].apiGatewayCore,
  $simpleAuthHeader: {
    headers: {
      Accept: "application/json",
      authorization: "",
    },
  },
  photo: null,
  id: null,
  submit: false,
  "Personal Information": "true",
  "Employment Details": "false",
  "Pay Information": "false",
  Applications: "false",
  Groups: "false",
  Documents: "false",
  loading: false,
  AdvanceLoanloading: false,
  levels: [],
  rolesToast: false,
  toast: false,
  toastText: "",
  toastColor: "",
  industries: [],
  // toastColor: `${"#EA2027" || "#009432"}`,
  orgAppearance: {
    defaultSideNavColor: "#321C3B",
    defaultBtnColor: "#F15A29",
    orgAppearanceData: {},
  },
  states: [
    { id: "1", name: "Abia" },
    { id: "2", name: "Adamawa" },
    { id: "3", name: "Anambra" },
    { id: "4", name: "Akwa Ibom" },
    { id: "5", name: "Bauchi" },
    { id: "6", name: "Bayelsa" },
    { id: "7", name: "Benue" },
    { id: "8", name: "Borno" },
    { id: "9", name: "Cross River" },
    { id: "10", name: "Delta" },
    { id: "11", name: "Ebonyi" },
    { id: "12", name: "Enugu" },
    { id: "13", name: "Edo" },
    { id: "14", name: "Ekiti" },
    { id: "15", name: "FCT - Abuja" },
    { id: "16", name: "Gombe" },
    { id: "17", name: "Imo" },
    { id: "18", name: "Jigawa" },
    { id: "19", name: "Kaduna" },
    { id: "20", name: "Kano" },
    { id: "21", name: "Katsina" },
    { id: "22", name: "Kebbi" },
    { id: "23", name: "Kogi" },
    { id: "24", name: "Kwara" },
    { id: "25", name: "Lagos" },
    { id: "26", name: "Nasarawa" },
    { id: "27", name: "Niger" },
    { id: "28", name: "Ogun" },
    { id: "29", name: "Ondo" },
    { id: "30", name: "Osun" },
    { id: "31", name: "Oyo" },
    { id: "32", name: "Plateau" },
    { id: "33", name: "Rivers" },
    { id: "34", name: "Sokoto" },
    { id: "35", name: "Taraba" },
    { id: "36", name: "Yobe" },
    { id: "37", name: "Zamfara" },
  ],
  nok: [
    { name: "Mother", id: "Mother" },
    { name: "Father", id: "Father" },
    { name: "Spouse", id: "Spouse" },
    { name: "Sister", id: "Sister" },
    { name: "Brother", id: "Brother" },
    { name: "Aunty", id: "Aunty" },
    { name: "Uncle", id: "Uncle" },
    { name: "Daughter", id: "Daughter" },
    { name: "Son", id: "Son" },
    { name: "Niece", id: "Niece" },
    { name: "Nephew", id: "Nephew" },
  ],
  countries: [
    { CallingCode: "93", Name: "Afghanistan", Alpha3Code: "AFG" },
    { CallingCode: "358", Name: "Åland Islands", Alpha3Code: "ALA" },
    { CallingCode: "355", Name: "Albania", Alpha3Code: "ALB" },
    { CallingCode: "213", Name: "Algeria", Alpha3Code: "DZA" },
    { CallingCode: "1684", Name: "American Samoa", Alpha3Code: "ASM" },
    { CallingCode: "376", Name: "Andorra", Alpha3Code: "AND" },
    { CallingCode: "244", Name: "Angola", Alpha3Code: "AGO" },
    { CallingCode: "1264", Name: "Anguilla", Alpha3Code: "AIA" },
    { CallingCode: "672", Name: "Antarctica", Alpha3Code: "ATA" },
    { CallingCode: "1268", Name: "Antigua and Barbuda", Alpha3Code: "ATG" },
    { CallingCode: "54", Name: "Argentina", Alpha3Code: "ARG" },
    { CallingCode: "374", Name: "Armenia", Alpha3Code: "ARM" },
    { CallingCode: "297", Name: "Aruba", Alpha3Code: "ABW" },
    { CallingCode: "61", Name: "Australia", Alpha3Code: "AUS" },
    { CallingCode: "43", Name: "Austria", Alpha3Code: "AUT" },
    { CallingCode: "994", Name: "Azerbaijan", Alpha3Code: "AZE" },
    { CallingCode: "1242", Name: "Bahamas", Alpha3Code: "BHS" },
    { CallingCode: "973", Name: "Bahrain", Alpha3Code: "BHR" },
    { CallingCode: "880", Name: "Bangladesh", Alpha3Code: "BGD" },
    { CallingCode: "1246", Name: "Barbados", Alpha3Code: "BRB" },
    { CallingCode: "375", Name: "Belarus", Alpha3Code: "BLR" },
    { CallingCode: "32", Name: "Belgium", Alpha3Code: "BEL" },
    { CallingCode: "501", Name: "Belize", Alpha3Code: "BLZ" },
    { CallingCode: "229", Name: "Benin", Alpha3Code: "BEN" },
    { CallingCode: "1441", Name: "Bermuda", Alpha3Code: "BMU" },
    { CallingCode: "975", Name: "Bhutan", Alpha3Code: "BTN" },
    {
      CallingCode: "591",
      Name: "Bolivia (Plurinational State of)",
      Alpha3Code: "BOL",
    },
    {
      CallingCode: "5997",
      Name: "Bonaire, Sint Eustatius and Saba",
      Alpha3Code: "BES",
    },
    {
      CallingCode: "387",
      Name: "Bosnia and Herzegovina",
      Alpha3Code: "BIH",
    },
    { CallingCode: "267", Name: "Botswana", Alpha3Code: "BWA" },
    { CallingCode: "", Name: "Bouvet Island", Alpha3Code: "BVT" },
    { CallingCode: "55", Name: "Brazil", Alpha3Code: "BRA" },
    {
      CallingCode: "246",
      Name: "British Indian Ocean Territory",
      Alpha3Code: "IOT",
    },
    { CallingCode: "673", Name: "Brunei Darussalam", Alpha3Code: "BRN" },
    { CallingCode: "359", Name: "Bulgaria", Alpha3Code: "BGR" },
    { CallingCode: "226", Name: "Burkina Faso", Alpha3Code: "BFA" },
    { CallingCode: "257", Name: "Burundi", Alpha3Code: "BDI" },
    { CallingCode: "238", Name: "Cabo Verde", Alpha3Code: "CPV" },
    { CallingCode: "855", Name: "Cambodia", Alpha3Code: "KHM" },
    { CallingCode: "237", Name: "Cameroon", Alpha3Code: "CMR" },
    { CallingCode: "1", Name: "Canada", Alpha3Code: "CAN" },
    { CallingCode: "1345", Name: "Cayman Islands", Alpha3Code: "CYM" },
    {
      CallingCode: "236",
      Name: "Central African Republic",
      Alpha3Code: "CAF",
    },
    { CallingCode: "235", Name: "Chad", Alpha3Code: "TCD" },
    { CallingCode: "56", Name: "Chile", Alpha3Code: "CHL" },
    { CallingCode: "86", Name: "China", Alpha3Code: "CHN" },
    { CallingCode: "61", Name: "Christmas Island", Alpha3Code: "CXR" },
    {
      CallingCode: "61",
      Name: "Cocos (Keeling) Islands",
      Alpha3Code: "CCK",
    },
    { CallingCode: "57", Name: "Colombia", Alpha3Code: "COL" },
    { CallingCode: "269", Name: "Comoros", Alpha3Code: "COM" },
    { CallingCode: "242", Name: "Congo", Alpha3Code: "COG" },
    {
      CallingCode: "243",
      Name: "Congo (Democratic Republic of the)",
      Alpha3Code: "COD",
    },
    { CallingCode: "682", Name: "Cook Islands", Alpha3Code: "COK" },
    { CallingCode: "506", Name: "Costa Rica", Alpha3Code: "CRI" },
    { CallingCode: "225", Name: "Côte d'Ivoire", Alpha3Code: "CIV" },
    { CallingCode: "385", Name: "Croatia", Alpha3Code: "HRV" },
    { CallingCode: "53", Name: "Cuba", Alpha3Code: "CUB" },
    { CallingCode: "599", Name: "Curaçao", Alpha3Code: "CUW" },
    { CallingCode: "357", Name: "Cyprus", Alpha3Code: "CYP" },
    { CallingCode: "420", Name: "Czech Republic", Alpha3Code: "CZE" },
    { CallingCode: "45", Name: "Denmark", Alpha3Code: "DNK" },
    { CallingCode: "253", Name: "Djibouti", Alpha3Code: "DJI" },
    { CallingCode: "1767", Name: "Dominica", Alpha3Code: "DMA" },
    { CallingCode: "1809", Name: "Dominican Republic", Alpha3Code: "DOM" },
    { CallingCode: "593", Name: "Ecuador", Alpha3Code: "ECU" },
    { CallingCode: "20", Name: "Egypt", Alpha3Code: "EGY" },
    { CallingCode: "503", Name: "El Salvador", Alpha3Code: "SLV" },
    { CallingCode: "240", Name: "Equatorial Guinea", Alpha3Code: "GNQ" },
    { CallingCode: "291", Name: "Eritrea", Alpha3Code: "ERI" },
    { CallingCode: "372", Name: "Estonia", Alpha3Code: "EST" },
    { CallingCode: "251", Name: "Ethiopia", Alpha3Code: "ETH" },
    {
      CallingCode: "500",
      Name: "Falkland Islands (Malvinas)",
      Alpha3Code: "FLK",
    },
    { CallingCode: "298", Name: "Faroe Islands", Alpha3Code: "FRO" },
    { CallingCode: "679", Name: "Fiji", Alpha3Code: "FJI" },
    { CallingCode: "358", Name: "Finland", Alpha3Code: "FIN" },
    { CallingCode: "33", Name: "France", Alpha3Code: "FRA" },
    { CallingCode: "594", Name: "French Guiana", Alpha3Code: "GUF" },
    { CallingCode: "689", Name: "French Polynesia", Alpha3Code: "PYF" },
    {
      CallingCode: "",
      Name: "French Southern Territories",
      Alpha3Code: "ATF",
    },
    { CallingCode: "241", Name: "Gabon", Alpha3Code: "GAB" },
    { CallingCode: "220", Name: "Gambia", Alpha3Code: "GMB" },
    { CallingCode: "995", Name: "Georgia", Alpha3Code: "GEO" },
    { CallingCode: "49", Name: "Germany", Alpha3Code: "DEU" },
    { CallingCode: "233", Name: "Ghana", Alpha3Code: "GHA" },
    { CallingCode: "350", Name: "Gibraltar", Alpha3Code: "GIB" },
    { CallingCode: "30", Name: "Greece", Alpha3Code: "GRC" },
    { CallingCode: "299", Name: "Greenland", Alpha3Code: "GRL" },
    { CallingCode: "1473", Name: "Grenada", Alpha3Code: "GRD" },
    { CallingCode: "590", Name: "Guadeloupe", Alpha3Code: "GLP" },
    { CallingCode: "1671", Name: "Guam", Alpha3Code: "GUM" },
    { CallingCode: "502", Name: "Guatemala", Alpha3Code: "GTM" },
    { CallingCode: "44", Name: "Guernsey", Alpha3Code: "GGY" },
    { CallingCode: "224", Name: "Guinea", Alpha3Code: "GIN" },
    { CallingCode: "245", Name: "Guinea-Bissau", Alpha3Code: "GNB" },
    { CallingCode: "592", Name: "Guyana", Alpha3Code: "GUY" },
    { CallingCode: "509", Name: "Haiti", Alpha3Code: "HTI" },
    {
      CallingCode: "",
      Name: "Heard Island and McDonald Islands",
      Alpha3Code: "HMD",
    },
    { CallingCode: "379", Name: "Holy See", Alpha3Code: "VAT" },
    { CallingCode: "504", Name: "Honduras", Alpha3Code: "HND" },
    { CallingCode: "852", Name: "Hong Kong", Alpha3Code: "HKG" },
    { CallingCode: "36", Name: "Hungary", Alpha3Code: "HUN" },
    { CallingCode: "354", Name: "Iceland", Alpha3Code: "ISL" },
    { CallingCode: "91", Name: "India", Alpha3Code: "IND" },
    { CallingCode: "62", Name: "Indonesia", Alpha3Code: "IDN" },
    {
      CallingCode: "98",
      Name: "Iran (Islamic Republic of)",
      Alpha3Code: "IRN",
    },
    { CallingCode: "964", Name: "Iraq", Alpha3Code: "IRQ" },
    { CallingCode: "353", Name: "Ireland", Alpha3Code: "IRL" },
    { CallingCode: "44", Name: "Isle of Man", Alpha3Code: "IMN" },
    { CallingCode: "972", Name: "Israel", Alpha3Code: "ISR" },
    { CallingCode: "39", Name: "Italy", Alpha3Code: "ITA" },
    { CallingCode: "1876", Name: "Jamaica", Alpha3Code: "JAM" },
    { CallingCode: "81", Name: "Japan", Alpha3Code: "JPN" },
    { CallingCode: "44", Name: "Jersey", Alpha3Code: "JEY" },
    { CallingCode: "962", Name: "Jordan", Alpha3Code: "JOR" },
    { CallingCode: "76", Name: "Kazakhstan", Alpha3Code: "KAZ" },
    { CallingCode: "254", Name: "Kenya", Alpha3Code: "KEN" },
    { CallingCode: "686", Name: "Kiribati", Alpha3Code: "KIR" },
    {
      CallingCode: "850",
      Name: "Korea (Democratic People's Republic of)",
      Alpha3Code: "PRK",
    },
    { CallingCode: "82", Name: "Korea (Republic of)", Alpha3Code: "KOR" },
    { CallingCode: "965", Name: "Kuwait", Alpha3Code: "KWT" },
    { CallingCode: "996", Name: "Kyrgyzstan", Alpha3Code: "KGZ" },
    {
      CallingCode: "856",
      Name: "Lao People's Democratic Republic",
      Alpha3Code: "LAO",
    },
    { CallingCode: "371", Name: "Latvia", Alpha3Code: "LVA" },
    { CallingCode: "961", Name: "Lebanon", Alpha3Code: "LBN" },
    { CallingCode: "266", Name: "Lesotho", Alpha3Code: "LSO" },
    { CallingCode: "231", Name: "Liberia", Alpha3Code: "LBR" },
    { CallingCode: "218", Name: "Libya", Alpha3Code: "LBY" },
    { CallingCode: "423", Name: "Liechtenstein", Alpha3Code: "LIE" },
    { CallingCode: "370", Name: "Lithuania", Alpha3Code: "LTU" },
    { CallingCode: "352", Name: "Luxembourg", Alpha3Code: "LUX" },
    { CallingCode: "853", Name: "Macao", Alpha3Code: "MAC" },
    {
      CallingCode: "389",
      Name: "Macedonia (the former Yugoslav Republic of)",
      Alpha3Code: "MKD",
    },
    { CallingCode: "261", Name: "Madagascar", Alpha3Code: "MDG" },
    { CallingCode: "265", Name: "Malawi", Alpha3Code: "MWI" },
    { CallingCode: "60", Name: "Malaysia", Alpha3Code: "MYS" },
    { CallingCode: "960", Name: "Maldives", Alpha3Code: "MDV" },
    { CallingCode: "223", Name: "Mali", Alpha3Code: "MLI" },
    { CallingCode: "356", Name: "Malta", Alpha3Code: "MLT" },
    { CallingCode: "692", Name: "Marshall Islands", Alpha3Code: "MHL" },
    { CallingCode: "596", Name: "Martinique", Alpha3Code: "MTQ" },
    { CallingCode: "222", Name: "Mauritania", Alpha3Code: "MRT" },
    { CallingCode: "230", Name: "Mauritius", Alpha3Code: "MUS" },
    { CallingCode: "262", Name: "Mayotte", Alpha3Code: "MYT" },
    { CallingCode: "52", Name: "Mexico", Alpha3Code: "MEX" },
    {
      CallingCode: "691",
      Name: "Micronesia (Federated States of)",
      Alpha3Code: "FSM",
    },
    {
      CallingCode: "373",
      Name: "Moldova (Republic of)",
      Alpha3Code: "MDA",
    },
    { CallingCode: "377", Name: "Monaco", Alpha3Code: "MCO" },
    { CallingCode: "976", Name: "Mongolia", Alpha3Code: "MNG" },
    { CallingCode: "382", Name: "Montenegro", Alpha3Code: "MNE" },
    { CallingCode: "1664", Name: "Montserrat", Alpha3Code: "MSR" },
    { CallingCode: "212", Name: "Morocco", Alpha3Code: "MAR" },
    { CallingCode: "258", Name: "Mozambique", Alpha3Code: "MOZ" },
    { CallingCode: "95", Name: "Myanmar", Alpha3Code: "MMR" },
    { CallingCode: "264", Name: "Namibia", Alpha3Code: "NAM" },
    { CallingCode: "674", Name: "Nauru", Alpha3Code: "NRU" },
    { CallingCode: "977", Name: "Nepal", Alpha3Code: "NPL" },
    { CallingCode: "31", Name: "Netherlands", Alpha3Code: "NLD" },
    { CallingCode: "687", Name: "New Caledonia", Alpha3Code: "NCL" },
    { CallingCode: "64", Name: "New Zealand", Alpha3Code: "NZL" },
    { CallingCode: "505", Name: "Nicaragua", Alpha3Code: "NIC" },
    { CallingCode: "227", Name: "Niger", Alpha3Code: "NER" },
    { CallingCode: "234", Name: "Nigeria", Alpha3Code: "NGA" },
    { CallingCode: "683", Name: "Niue", Alpha3Code: "NIU" },
    { CallingCode: "672", Name: "Norfolk Island", Alpha3Code: "NFK" },
    {
      CallingCode: "1670",
      Name: "Northern Mariana Islands",
      Alpha3Code: "MNP",
    },
    { CallingCode: "47", Name: "Norway", Alpha3Code: "NOR" },
    { CallingCode: "968", Name: "Oman", Alpha3Code: "OMN" },
    { CallingCode: "92", Name: "Pakistan", Alpha3Code: "PAK" },
    { CallingCode: "680", Name: "Palau", Alpha3Code: "PLW" },
    { CallingCode: "970", Name: "Palestine, State of", Alpha3Code: "PSE" },
    { CallingCode: "507", Name: "Panama", Alpha3Code: "PAN" },
    { CallingCode: "675", Name: "Papua New Guinea", Alpha3Code: "PNG" },
    { CallingCode: "595", Name: "Paraguay", Alpha3Code: "PRY" },
    { CallingCode: "51", Name: "Peru", Alpha3Code: "PER" },
    { CallingCode: "63", Name: "Philippines", Alpha3Code: "PHL" },
    { CallingCode: "64", Name: "Pitcairn", Alpha3Code: "PCN" },
    { CallingCode: "48", Name: "Poland", Alpha3Code: "POL" },
    { CallingCode: "351", Name: "Portugal", Alpha3Code: "PRT" },
    { CallingCode: "1787", Name: "Puerto Rico", Alpha3Code: "PRI" },
    { CallingCode: "974", Name: "Qatar", Alpha3Code: "QAT" },
    { CallingCode: "383", Name: "Republic of Kosovo", Alpha3Code: "KOS" },
    { CallingCode: "262", Name: "Réunion", Alpha3Code: "REU" },
    { CallingCode: "40", Name: "Romania", Alpha3Code: "ROU" },
    { CallingCode: "7", Name: "Russian Federation", Alpha3Code: "RUS" },
    { CallingCode: "250", Name: "Rwanda", Alpha3Code: "RWA" },
    { CallingCode: "590", Name: "Saint Barthélemy", Alpha3Code: "BLM" },
    {
      CallingCode: "290",
      Name: "Saint Helena, Ascension and Tristan da Cunha",
      Alpha3Code: "SHN",
    },
    {
      CallingCode: "1869",
      Name: "Saint Kitts and Nevis",
      Alpha3Code: "KNA",
    },
    { CallingCode: "1758", Name: "Saint Lucia", Alpha3Code: "LCA" },
    {
      CallingCode: "590",
      Name: "Saint Martin (French part)",
      Alpha3Code: "MAF",
    },
    {
      CallingCode: "508",
      Name: "Saint Pierre and Miquelon",
      Alpha3Code: "SPM",
    },
    {
      CallingCode: "1784",
      Name: "Saint Vincent and the Grenadines",
      Alpha3Code: "VCT",
    },
    { CallingCode: "685", Name: "Samoa", Alpha3Code: "WSM" },
    { CallingCode: "378", Name: "San Marino", Alpha3Code: "SMR" },
    {
      CallingCode: "239",
      Name: "Sao Tome and Principe",
      Alpha3Code: "STP",
    },
    { CallingCode: "966", Name: "Saudi Arabia", Alpha3Code: "SAU" },
    { CallingCode: "221", Name: "Senegal", Alpha3Code: "SEN" },
    { CallingCode: "381", Name: "Serbia", Alpha3Code: "SRB" },
    { CallingCode: "248", Name: "Seychelles", Alpha3Code: "SYC" },
    { CallingCode: "232", Name: "Sierra Leone", Alpha3Code: "SLE" },
    { CallingCode: "65", Name: "Singapore", Alpha3Code: "SGP" },
    {
      CallingCode: "1721",
      Name: "Sint Maarten (Dutch part)",
      Alpha3Code: "SXM",
    },
    { CallingCode: "421", Name: "Slovakia", Alpha3Code: "SVK" },
    { CallingCode: "386", Name: "Slovenia", Alpha3Code: "SVN" },
    { CallingCode: "677", Name: "Solomon Islands", Alpha3Code: "SLB" },
    { CallingCode: "252", Name: "Somalia", Alpha3Code: "SOM" },
    { CallingCode: "27", Name: "South Africa", Alpha3Code: "ZAF" },
    {
      CallingCode: "500",
      Name: "South Georgia and the South Sandwich Islands",
      Alpha3Code: "SGS",
    },
    { CallingCode: "211", Name: "South Sudan", Alpha3Code: "SSD" },
    { CallingCode: "34", Name: "Spain", Alpha3Code: "ESP" },
    { CallingCode: "94", Name: "Sri Lanka", Alpha3Code: "LKA" },
    { CallingCode: "249", Name: "Sudan", Alpha3Code: "SDN" },
    { CallingCode: "597", Name: "Suriname", Alpha3Code: "SUR" },
    {
      CallingCode: "4779",
      Name: "Svalbard and Jan Mayen",
      Alpha3Code: "SJM",
    },
    { CallingCode: "268", Name: "Swaziland", Alpha3Code: "SWZ" },
    { CallingCode: "46", Name: "Sweden", Alpha3Code: "SWE" },
    { CallingCode: "41", Name: "Switzerland", Alpha3Code: "CHE" },
    { CallingCode: "963", Name: "Syrian Arab Republic", Alpha3Code: "SYR" },
    {
      CallingCode: "886",
      Name: "Taiwan, Province of China",
      Alpha3Code: "TWN",
    },
    { CallingCode: "992", Name: "Tajikistan", Alpha3Code: "TJK" },
    {
      CallingCode: "255",
      Name: "Tanzania, United Republic of",
      Alpha3Code: "TZA",
    },
    { CallingCode: "66", Name: "Thailand", Alpha3Code: "THA" },
    { CallingCode: "670", Name: "Timor-Leste", Alpha3Code: "TLS" },
    { CallingCode: "228", Name: "Togo", Alpha3Code: "TGO" },
    { CallingCode: "690", Name: "Tokelau", Alpha3Code: "TKL" },
    { CallingCode: "676", Name: "Tonga", Alpha3Code: "TON" },
    { CallingCode: "1868", Name: "Trinidad and Tobago", Alpha3Code: "TTO" },
    { CallingCode: "216", Name: "Tunisia", Alpha3Code: "TUN" },
    { CallingCode: "90", Name: "Turkey", Alpha3Code: "TUR" },
    { CallingCode: "993", Name: "Turkmenistan", Alpha3Code: "TKM" },
    {
      CallingCode: "1649",
      Name: "Turks and Caicos Islands",
      Alpha3Code: "TCA",
    },
    { CallingCode: "688", Name: "Tuvalu", Alpha3Code: "TUV" },
    { CallingCode: "256", Name: "Uganda", Alpha3Code: "UGA" },
    { CallingCode: "380", Name: "Ukraine", Alpha3Code: "UKR" },
    { CallingCode: "971", Name: "United Arab Emirates", Alpha3Code: "ARE" },
    {
      CallingCode: "44",
      Name: "United Kingdom of Great Britain and Northern Ireland",
      Alpha3Code: "GBR",
    },
    {
      CallingCode: "",
      Name: "United States Minor Outlying Islands",
      Alpha3Code: "UMI",
    },
    {
      CallingCode: "1",
      Name: "United States of America",
      Alpha3Code: "USA",
    },
    { CallingCode: "598", Name: "Uruguay", Alpha3Code: "URY" },
    { CallingCode: "998", Name: "Uzbekistan", Alpha3Code: "UZB" },
    { CallingCode: "678", Name: "Vanuatu", Alpha3Code: "VUT" },
    {
      CallingCode: "58",
      Name: "Venezuela (Bolivarian Republic of)",
      Alpha3Code: "VEN",
    },
    { CallingCode: "84", Name: "Viet Nam", Alpha3Code: "VNM" },
    {
      CallingCode: "1284",
      Name: "Virgin Islands (British)",
      Alpha3Code: "VGB",
    },
    {
      CallingCode: "1 340",
      Name: "Virgin Islands (U.S.)",
      Alpha3Code: "VIR",
    },
    { CallingCode: "681", Name: "Wallis and Futuna", Alpha3Code: "WLF" },
    { CallingCode: "212", Name: "Western Sahara", Alpha3Code: "ESH" },
    { CallingCode: "967", Name: "Yemen", Alpha3Code: "YEM" },
    { CallingCode: "260", Name: "Zambia", Alpha3Code: "ZMB" },
    { CallingCode: "263", Name: "Zimbabwe", Alpha3Code: "ZWE" },
  ],
};

export default state;
