import AXIOS from "axios";
import apiFactory from "@/services/apiFactory";

const actions = {
  getAppSettings({ commit }) {
    apiFactory.$_getAppSettings().then((response) => {
      commit("UPDATE_APP_SETTINGS", response.data.appSettings);
    });
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },
  refreshLinchpin({ state, commit }) {
    return AXIOS.get(
      `${state.$API_GATEWAY}auth/refresh_linchpin`,
      state.$simpleAuthHeader
    ).then((response) => {
      commit("UPDATE_LOCALSTORAGE_WITH_LINCHPIN", response.data);
    });
  },
  getAppDefaultApiResources({ state, commit }) {
    return AXIOS.get(
      `${state.$API_GATEWAY}app_defaults`,
      state.$simpleAuthHeader
    ).then((response) => {
      commit("UPDATE_APP_DEFAULT_API_RESOURCES", response.data);
    });
  },
  async getLevels({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getLevels();
      commit("SET_LEVELS", data.levels);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getPrivileges({ commit }) {
    try {
      const { data } = await apiFactory.$_getPrivileges();
      commit("SET_PRIVILEGES", data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getNotifications({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getNotifications();
      commit("SET_ALL_NOTIFICATIONS", data.notifications);
      commit("SET_LOADING", false);
    } catch (err) {
      Promise.reject(err?.response);
    }
  },

  async getSubscription({ commit }) {
    try {
      const { data } = await apiFactory.$_getSubscriptionProduct();
      commit("GET_SUBSCRIPTION", data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getIndustries({ commit }) {
    try {
      const { data } = await apiFactory.$_getIndustries();
      commit("SET_INDUSTRIES", data.industries);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getBanks({ commit }) {
    try {
      const { data } = await apiFactory.$_getBanks();
      commit("GET_BANKS", data.data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getOrgAppearance({ commit }) {
    try {
      const { data } = await apiFactory.$_getOrgAppearance();
      commit("GET_ORG_APPEARANCE", data.orgAppearance);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  updateOrgAppearance({ commit }, payload) {
    commit("UPDATE_ORG_APPEARANCE", payload);
  },
};

export default actions;
