import AXIOS from "axios/index";
import apiFactory from "@/services/apiFactory";
import { employeeApi } from "../../services/employeeApiFactory";

export default {
  create({ rootState }, payload) {
    // let url = `employee/${rootState.linchpin.org.id}/user`
    const url = `${rootState.linchpin.org.id}/employees`;
    return AXIOS.post(
      rootState.$API_GATEWAY + url,
      payload,
      rootState.$simpleAuthHeader
    );
  },

  updateEmployeePhoto({ commit }, payload) {
    commit("UPDATE_EMPLOYEE_PHOTO", payload);
  },

  invite({ rootState }, payload) {
    const url = `${rootState.linchpin.org.id}/employees/invite`;
    return AXIOS.post(
      rootState.$API_GATEWAY + url,
      payload,
      rootState.$simpleAuthHeader
    );
  },

  async getEmployeeInvite({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getEmployeeInvite(payload);
      commit("USER__INVITE", data);
      commit("SET_LOADING", false);
    } catch (error) {
      throw new Error(error.response);
    }
  },

  async get_Employees({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getAllEmployees(payload);
      commit("SET_EMPLOYEES", data);
      commit("SET_LOADING", false);
    } catch (error) {
      throw new Error(error.response);
    }
  },
  async getEmployeesWithQueries({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getAllEmployeesWithQueries(payload);
      commit("SET_EMPLOYEES", data.data.employees);
      commit("SET_METADATA", data.data.meta);
      commit("SET_LOADING", false);
    } catch (error) {
      throw new Error(error.response);
    }
  },
  async getEmployeesEss({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getAllEmployeesEss(payload);
      commit("SET_EMPLOYEES", data.data.employees);
      commit("SET_METADATA", data.data.meta);
      commit("SET_LOADING", false);
    } catch (error) {
      throw new Error(error.response);
    }
  },
  async getReportingWithQueries({ commit }, payload) {
        try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getAllDirectReportingWithQueries(payload);
      commit("SET_EMPLOYEES", data.data.employees);
      commit("SET_METADATA", data.data.meta);
      commit("SET_LOADING", false);
        }
        catch (error) {
          throw new Error(error.response);
        }
  },
  async getReportingSuspendedEmployees({ commit }, payload) {
        try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getManagerSuspendedEmployees(payload);
      commit("SET_EMPLOYEES", data.data.employeess);
      commit("SET_METADATA", data.data.meta);
      commit("SET_LOADING", false);
        }
        catch (error) {
          throw new Error(error.response);
        }
  },
  async getReportingDisengagedEmployees({ commit }, payload) {
        try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getManagerDisengagedEmployees(payload);
      commit("SET_EMPLOYEES", data.data.employee);
      commit("SET_METADATA", data.data.meta);
      commit("SET_LOADING", false);
        }
        catch (error) {
          throw new Error(error.response);
        }
  },
  async getProbationEmployees({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_employeeOnProbation(payload);
      commit("SET_PROBATION_EMPLOYEES", data.data.employees);
      commit("SET_METADATA", data.data.meta);
      commit("SET_LOADING", false);
    } catch (error) {
      throw new Error(error.response);
    }
  },
  async getManagerEmployees({ commit }, payload) {
    try {
      const { data } = await apiFactory.$_employeesConfirmation(payload);
      commit("SET_PROBATION_EMPLOYEES", data.data.employees);
      commit("SET_METADATA", data.data.meta);
    } catch (error) {
      throw new Error(error.response);
    }
  },
  getOneEmployeeEmployement({ rootState }, payload) {
    const url = `${rootState.linchpin.org.id}/${payload.id}`;
    return AXIOS.get(rootState.$API_GATEWAY + url, rootState.$simpleAuthHeader);
  },

  async getEmploymentDetails({ commit }, payload) {
    try {
      const { data } = await apiFactory.$_getOneEmployee(payload);
      commit("SET_EMPLOYMENT_DETAILS", data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async updateEmployeePersonalInformation({ commit }, payload) {
    try {
      commit(
        "SET_TOAST",
        { text: "Successfully updated personal information", color: "success" },
        { root: true }
      );
      const { data } = await apiFactory.$_updateEmployee(payload, "type=bio");
      commit("SET_EMPLOYEE_PERSONAL_INFORMATION", data);
    } catch (err) {
      throw new Error(err.response);
    }
  },

  async getOneEmployeeAll({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getOneEmployee(payload, "type=all");
      commit("SET_ALL_EMPLOYEE_DETAILS", data);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getOneEmployeePersonalInfo({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await employeeApi.$_getOneEmployeePersonalInfo(payload);
      commit("SET_EMPLOYEE_PERSONAL_INFO", data);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },

  async getOneEmployeeEmployementInfo({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await employeeApi.$_getOneEmployeeEmploymentInfo(payload);
      commit("SET_EMPLOYEE_EMPLOYMENT_INFO", data);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getOneEmployeePaymentInfo({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await employeeApi.$_getOneEmployeePaymentInfo(payload);
      commit("SET_EMPLOYEE_PAYMENT_INFO", data);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },

  async getEssPermissions({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getEssPermissions();
      commit("SET_ESS_PERMISSIONS", data.permissions);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  editOneEmployee({ rootState }, payload) {
    // let url = `user/${rootState.linchpin.org.id}`
    const url = `${rootState.linchpin.org.id}/employees/${payload.id}?type=bio`;
    return AXIOS.patch(
      rootState.$API_GATEWAY + url,
      payload.employeeData,
      rootState.$simpleAuthHeader
    );
  },
  editOneEmployeeNoQuery({ rootState }, payload) {
    // let url = `user/${rootState.linchpin.org.id}`
    const url = `${rootState.linchpin.org.id}/employees/${payload.id}`;
    return AXIOS.patch(
      rootState.$API_GATEWAY + url,
      payload.employeeData,
      rootState.$simpleAuthHeader
    );
  },
  async getEmployeeAnnouncements({ commit }) {
    try {
      const { data: response } = await apiFactory.$_getEmployeeAnnouncements();
      commit("SET_EMPLOYEE_ANNOUNCEMENTS", response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getEmployeeAnnouncement({ commit }, payload) {
    try {
      const { data: response } = await apiFactory.$_getEmployeeAnnouncement(
        payload
      );
      commit("SET_EMPLOYEE_ANNOUNCEMENT", response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getEmployeeAnnouncementCategories({ commit }) {
    try {
      const {
        data: response
      } = await apiFactory.$_getEmployeeAnnouncementCategories();
      commit("SET_EMPLOYEE_ANNOUNCEMENT_CATEGORIES", response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getEmployeeAnnouncementRecipients({ commit }) {
    try {
      const {
        data: response
      } = await apiFactory.$_getEmployeeAnnouncementRecipients();
      commit("SET_EMPLOYEE_ANNOUNCEMENT_RECIPIENTS", response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async addEmployeeAnnouncement({ commit }, payload) {
    try {
      const { data: response } = await apiFactory.$_addEmployeeAnnouncement(
        payload
      );
      commit(
        "SET_TOAST",
        { text: "Announcement successful", color: "success" },
        { root: true }
      );
      commit("ADD_NEW_EMPLOYEE_ANNOUNCEMENT", response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async addEmployeeAnnouncementCategory({ commit }, payload) {
    try {
      const {
        data: response
      } = await apiFactory.$_addEmployeeAnnouncementCategory(payload);
      commit(
        "SET_TOAST",
        { text: "Successfully created category", color: "success" },
        { root: true }
      );
      commit("ADD_NEW_EMPLOYEE_ANNOUNCEMENT_CATEGORY", response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  }
};
