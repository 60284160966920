export default [
  {
    path: "/ess/manager-self-service/leave",
    name: "EssMSSLeave",
    component: () => import("@/modules/Ess/ManagerSelfService/benefits/leave"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/annual-leave-plan/:leaveYear",
    name: "EssViewAdminAnnualLeavePlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewAnnualLeavePlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/annual-leave-plan/:year/:id",
    name: "EssViewEmployeeAnnualLeavePlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewEmployeeAnnualLeavePlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/employee-annual-leave-plan/conflicts/:year/:startDate/:id/:conflictId",
    name: "EssEmployeeCalendarViewPlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/EmployeeCalendarViewPlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/leave-balance/:id",
    name: "EssViewYearEndLeaveBalance",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewYearEndLeaveBalance"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/manger/overtime",
    name: "ManagerOvertimeRequests",
    component: () => import("@/modules/Ess/ManagerSelfService/benefits/overtime"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/manager/advance-and-loan",
    name: "ManagerAdvanceLoans",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/benefits/advance"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/direct-reporting",
    name: "ManagerDirectReporting",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/DirectReporting/index"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/direct-reporting/:id",
    name: "ManagerDirectReportingDetails",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/DirectReporting/index"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/confirmation",
    name: "EssMSSConfirmation",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/confirmation"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/confirmation/view-assessment/:id",
    name: "EssViewAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/viewAssessment"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/confirmation/confirm-assessment/:id",
    name: "EssCreateAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/createAssessment"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/promotions",
    name: "EssMSSPromotions",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/promotions"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "ess/compensation/review",
    name: "EssCompensationReview",
    component: () => import("@/modules/Ess/Compensation/Review"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "ess/compensation/review/details/:id",
    name: "EssCompensationReviewDetailedReport",
    component: () => import("@/modules/Ess/Compensation/Review/DetailedReport"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/delegation",
    name: "EssMSSDelegation",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/delegation"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/re-deployment",
    name: "EssMSSRedeployment",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/redeployment"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
]
