export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_MODULES(state, payload) {
    state.modules = payload;
  },
  SET_ROLE(state, payload) {
    state.role = payload;
  },
  SET_LEVEL_PERMISSIONS(state, payload) {
    state.levelPermissions = payload;
  },
};
