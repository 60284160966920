import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "timeAttendance",
    name: "TimeAttendance",
    component: () => import("@/modules/Admin/TimeAttendance"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "timeAttendance/employee-schedule/:id",
    name: "EmployeeSchedule",
    component: () => import("@/modules/Admin/TimeAttendance/EmployeeSchedule"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  }
];
