import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/analytics/organisation",
    name: "OrganisationAnalytics",
    component: () => import("@/modules/Admin/Analytics/OrganisationAnalytics"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/analytics/employee-demographics",
    name: "EmployeeAnalytics",
    component: () => import("@/modules/Admin/Analytics/EmployeeAnalytics"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/analytics/payroll",
    name: "PayrollAnalytics",
    component: () => import("@/modules/Admin/Analytics/PayrollAnalytics"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "/analytics/compensation",
    name: "CompensationAnalytics",
    component: () => import("@/modules/Admin/Analytics/CompensationAnalytics"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
];
