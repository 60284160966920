import state from './moduleLocationState';
import mutations from './moduleLocationMutations';
import actions from './moduleLocationActions';
import getters from './moduleLocationGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
