export default {
  handleDisablingPayItem(payItem) {
    if (
      payItem.slug === 'transportAllowance'
      || payItem.slug === 'housingAllowance'
      || payItem.slug === 'Basic'
    ) {
      return true;
    }
    return false;
  },
  pensionList({ PFAOrgList }) {
    return PFAOrgList.map((item) => ({
      id: item.id,
      enrollmentNumber: item.orgPfaNo,
      pfa: item.pfaName,
    }));
  },
  pensionSettings(state, getters, rootState) {
    const { divisions, payItems } = state.PFAOrgSettings;
    return {
      orgId: rootState.org.orgProfile.id,
      divisions,
      payItems: payItems.map((item) => ({
        id: item.id,
        isPartOfPension: item.isPartOfPension,
        payItem: item.payItem,
        disabled:
            item.payItem.slug === 'transportAllowance'
            || item.payItem.slug === 'housingAllowance'
            || item.payItem.slug === 'basic',
      })),
    };
  },
};
