import state from './moduleAuthenticationState';
import mutations from './moduleAuthenticationMutations';
import actions from './moduleAuthenticationActions';
import getters from './moduleAuthenticationGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
