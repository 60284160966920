/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, OrgId } from './orgRepository';

export const salaryAdvanceApi = {
  $_applyForSalaryAdvance(payload) {
    const url = '/salary-advance/requests';
    return AxiosHumanar.post(url, payload);
  },
  $_getAllOrgSalaryAdvance() {
    const url = `/salary-advance/requests/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeSalaryAdvance({ id }) {
    const url = `/salary-advance/requests/employee/${id}`;
    return AxiosHumanar.get(url);
  },
  $_getAllSalaryAdvanceSettings() {
    const url = `/salary-advance/settings/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getSalaryAdvanceSettings() {
    const url = `/salary-advance/settings/org/${OrgId}/levels/9638a7c3-9f34-49c3-927f-2a9383b14f0f`;
    return AxiosHumanar.get(url);
  },
  $_createSalaryAdvance(payload) {
    const url = '/salary-advance/settings';
    return AxiosHumanar.patch(url, payload);
  },
  $_salaryAdvanceApproval(payload) {
    const url = '/salary-advance/requests/approval';
    return AxiosHumanar.patch(url, payload);
  },
  $_updateSalaryAdvanceSettings(payload) {
    const url = '/salary-advance/settings';
    return AxiosHumanar.patch(url, payload);
  },
  $_inheritSalaryAdvanceSetting(payload) {
    const url = '/salary-advance/settings/inherit';
    return AxiosHumanar.patch(url, payload);
  },

};
