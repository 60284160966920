export default {
  SET_ISLOADING(state, payload) {
    state.isLoading = payload
  },

  SET_TOASTED(state, payload){
    state.toasted = payload
  },

  SET_APPROVAL_LIST(state, payload){
    state.approversList = payload
  },

  SET_REQUEST_ID(state, payload){
    state.requestId = payload
  },

  SET_MY_APPROVAL_REQUESTS(state, payload){
    state.myApprovalRequests = payload
  }
}
