import Vue from "vue";
import Router from "vue-router";
import adminRoutes from "./admin";
import essRoutes from "./ess";
// TODO: remove all no cycle and fix the issue
// eslint-disable-next-line import/no-cycle
import store from "../store";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "TokenStaging",
    component: () => import("../TokenStaging")
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../modules/Admin"),
    children: [
      ...adminRoutes,
      {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        component: () => import("@/modules/Admin/dashboard"),
        meta: {
          authRequired: true,
          adminRequire: true
        }
      },
      {
        path: "/admin/error403",
        name: "ErrorRestricted",
        component: () => import("@/modules/Admin/error403"),
        meta: {
          authRequired: true,
          adminRequire: true
        }
      },
      {
        path: "/admin/notification",
        name: "Notification",
        component: () => import("@/components/NotificationDetails"),
        meta: {
          authRequired: true,
          adminRequire: true
        }
      },
      {
        path: "/admin/error404",
        name: "ErrorNoAccess",
        component: () => import("@/modules/Admin/error404"),
        meta: {
          authRequired: true,
          adminRequire: true
        }
      }
    ]
  },
  {
    path: "",
    name: "Home",
    component: () => import("../modules/Ess"),
    children: [
      ...essRoutes,
      {
        path: "/ess/dashboard",
        name: "EssDashboard",
        component: () => import("@/modules/Ess/Dashboard"),
        meta: {
          authRequired: true,
          employeeUrl: true
        }
      },
      {
        path: "/ess/notification",
        name: "EssNotification",
        component: () => import("@/components/NotificationDetails"),
        meta: {
          authRequired: true,
          employeeUrl: true
        }
      }
    ]
  }
];

const router = new Router({
  mode: "history",
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
};

router.beforeEach((to, from, next) => {
  store.commit("UPDATE_STATE_FROM_LOCALSTORAGE");
  store.commit("SET_APP_SUBSCRIPTION");

  const users = JSON.parse(localStorage.getItem("linchpin"));
  const { authRequired, adminRequire, employeeUrl, middleware } = to.meta;
  if (authRequired && adminRequire) {
    if (users.user.isRoot || users.user.roles.humanar) {
      if (!middleware) return next();

      const middle = Array.isArray(middleware) ? middleware : [middleware];

      const context = { to, from, next, store };
      const nextMiddleware = nextFactory(context, middle, 0);
      return middleware[0]({ ...context, next: nextMiddleware });
    }
    return next({ name: "EssDashboard" });
  }
  if (authRequired && employeeUrl) {
    if (users.user.isEmployee) {
      return next();
    }
    return next({ name: "AdminDashboard" });
  }
  return next();
});
export default router;
