import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "compensation",
    name: "CompensationOverview",
    component: () => import("@/modules/Admin/Compensation"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "compensation/pay-insight",
    name: "CompensationPayInsight",
    component: () => import("@/modules/Admin/Compensation/PayInsight"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "compensation/pay-insight/view-employees/:id",
    name: "ViewEmployeePayInsight",
    component: () => import("@/modules/Admin/Compensation/PayInsight/ViewEmployeePayInsight"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "compensation/pay-insight/adjust-paygrade",
    name: "BulkAdjustPayGrade",
    component: () => import("@/modules/Admin/Compensation/PayInsight/AdjustPayGrade"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
    props: true
  },
  {
    path: "compensation/pay-insight/adjust-paygrade/:id",
    name: "AdjustPayGrade",
    component: () => import("@/modules/Admin/Compensation/PayInsight/AdjustPayGrade"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
    props: true
  },
  {
    path: "compensation/pay-insight/revision-history/:id",
    name: "RevisionHistory",
    component: () => import("@/modules/Admin/Compensation/PayInsight/RevisionHistory"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "compensation/review",
    name: "CompensationReview",
    component: () => import("@/modules/Admin/Compensation/Review"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "compensation/review/details/:id",
    name: "CompensationReviewDetailedReport",
    component: () => import("@/modules/Admin/Compensation/Review/DetailedReport"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "compensation/review/new-review",
    name: "CreateCompensationReview",
    component: () => import("@/modules/Admin/Compensation/Review/CreateCompensationReview"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "compensation/review/:id",
    name: "UpdateCompensationReview",
    component: () => import("@/modules/Admin/Compensation/Review/CreateCompensationReview"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
  {
    path: "compensation/pay-benchmark",
    name: "CompensationPayBenchMark",
    component: () => import("@/modules/Admin/Compensation/PayBenchMark"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    },
  },
];
