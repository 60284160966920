import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/performance/OrganisationalGoal",
    name: "OrganisationlGoal",
    component: () =>
      import("@/modules/Admin/PerformanceModule/OrganisationalGoal"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/OrganisationalGoal/Current/NewGoal",
    name: "performance Organisationl Current New goal",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/OrganisationalGoal/CurrentGoals/NewGoal"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/OrganisationalGoal/Current/EditGoal/:id",
    name: "performance Organisationl Current Edit goal",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/OrganisationalGoal/CurrentGoals/EditGoal"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/OrganisationalGoal/LongTerm/NewGoal",
    name: "performance Organisationl LongTerm New goal",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/OrganisationalGoal/LongTermGoals/NewGoal"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/OrganisationalGoal/LongTerm/EditGoal/:id",
    name: "performance Organisationl LongTerm Edit goal",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/OrganisationalGoal/LongTermGoals/EditGoal"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },

  {
    path: "/performance/agreements",
    name: "PerformanceAgreement",
    component: () =>
      import("@/modules/Admin/PerformanceModule/PerformanceAgreement"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },

  {
    path: "/performance/appraisals/status-reports/:id/:year",
    name: "EmployeeStatusReports",
    component: () => import("@/modules/Admin/PerformanceModule/PerformanceAgreement/EmployeeStatusReport/"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },

  {
    path: "/performance/agreements/agreement-details/:id/:year",
    name: "PerformanceAgreementDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceAgreement/AgreementDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/agreements/employee-agreement/:id",
    name: "PerformanceEmployeeDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceAgreement/EmployeeAgreement"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  // {
  //   path: "/performance/agreements/employee-details",
  //   name: "PerformanceEmployeeDetails",
  //   component: () =>
  //     import(
  //       "@/modules/Admin/PerformanceModule/PerformanceAgreement/EmployeeDetails"
  //     ),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true,
  //     middleware: [subscription]
  //   }
  // },
  {
    path: "/performance/appraisals",
    name: "PerformanceAppraisals",
    component: () => import("@/modules/Admin/PerformanceModule/PerformanceAppraisals"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  // {
  //   path: "/performance/appraisals/organisation-appraisal/:id/:tmpId/:active",
  //   name: "PerformanceOrganisationAppraisal",
  //   component: () => import( "@/modules/Admin/PerformanceModule/PerformanceAppraisals/OrganisationAppraisal" ),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true,
  //     middleware: [subscription]
  //   }
  // },
  {
    path: "/performance/appraisals/organisation-appraisal/:year/:cycleId/:cycleName",
    name: "PerformanceOrganisationAppraisal",
    component: () => import( "@/modules/Admin/PerformanceModule/PerformanceAppraisals/OrganisationAppraisal" ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/appraisals/organisation-appraisal/organisation-performance",
    name: "OrganisationAppraisalPerformance",
    component: () => import( "@/modules/Admin/PerformanceModule/PerformanceAppraisals/OrganisationPerformance" ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  // {
  //   path: "/performance/appraisals/employee-appraisal/:id",
  //   name: "PerformanceEmployeeApprisal",
  //   component: () => import( "@/modules/Admin/PerformanceModule/PerformanceAppraisals/EmployeeAppraisal" ),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true,
  //     middleware: [subscription]
  //   }
  // },
  {
    path: "/performance/appraisals/employee-appraisal/:year/:userId/:cycle/:goalId",
    name: "PerformanceEmployeeAppraisal",
    component: () => import( "@/modules/Admin/PerformanceModule/PerformanceAppraisals/EmployeeAppraisal" ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  // {
  //   path: "/performance/appraisals/appraisal-detail/:id/:tmpId",
  //   name: "PerformanceAppraisalDetail",
  //   component: () => import("@/modules/Admin/PerformanceModule/PerformanceAppraisals/AppraisalDetails"),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true,
  //     middleware: [subscription]
  //   }
  // },
  {
    path: "/performance/appraisals/appraisal-detail/:year/:cycleId",
    name: "PerformanceAppraisalDetail",
    component: () => import("@/modules/Admin/PerformanceModule/PerformanceAppraisals/AppraisalDetails"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  // {
  //   path: "/performance/appraisals/performance-history",
  //   name: "PerformanceEmployeeApprisal",
  //   component: () => import("@/modules/Admin/PerformanceModule/PerformanceAppraisals/PerformanceHistory"),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true,
  //     middleware: [subscription]
  //   }
  // },
  {
    path: "/performance/appraisals/performance-history",
    name: "EmployeePerformanceHistory",
    component: () => import("@/modules/Admin/PerformanceModule/PerformanceAppraisals/EmployeePerformanceHistory"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/appraisals/organisation-scorecard/:year/:cycleId",
    name: "OrganisationScorecard",
    component: () => import("@/modules/Admin/PerformanceModule/PerformanceAppraisals/OrganisationScorecard"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/moderation",
    name: "PerformanceModeration",
    component: () =>
      import("@/modules/Admin/PerformanceModule/PerformanceModeration"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/moderation/moderation-details/:year/:goalId",
    name: "FunctionalModerationDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceModeration/FunctionalModerationDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  // {
  //   path: "/performance/moderation/ceo-moderation-details",
  //   name: "PerformanceCEOModerationDetails",
  //   component: () =>
  //     import(
  //       "@/modules/Admin/PerformanceModule/PerformanceModeration/CEOModerationDetails"
  //     ),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true,
  //     middleware: [subscription]
  //   }
  // },
  {
    path: "/performance/performance-improvement",
    name: "PerformanceImprovment",
    component: () =>
      import("@/modules/Admin/PerformanceModule/PerformanceImprovment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/performance-improvement/pip/:id",
    name: "PerformanceImprovmentDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceImprovment/PerformanceImprovementDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/performance/performance-improvement/pip/plan/:id/:templateId",
    name: "PerformanceImprovementPlan",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceImprovment/ImprovementPlan"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path:"/performance/performance-improvement/ppr/:id",
    name: "PerformanceReviewDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceImprovment/PerformanceReviewDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path:"/performance/performance-improvement/pcr/:id/:templateId",
    name: "PerformanceCloseOut",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceImprovment/PerformanceCloseOut"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path:"/performance/performance-improvement/ppr/details/:id/:tmpId",
    name: "ReviewSessionDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceImprovment/ReviewSession/ReviewSessionDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path:"/performance/performance-improvement/pcs/:id",
    name: "PerformanceCloseoutDetails",
    component: () =>
      import(
        "@/modules/Admin/PerformanceModule/PerformanceImprovment/PerformanceCloseoutDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  }
];
