/* eslint-disable import/no-cycle */
import router from "../../router"
import { $AuthUser } from "../../services/orgRepository"

export default {
  approverInfo: (state) => state.approversList.find((approver) => {
    if (router.currentRoute.query.slug === 'history') {
      return approver.requestId === router.currentRoute.params.id
    }

    if (approver.requestId === router.currentRoute.params.id) {
      if (approver.userId === $AuthUser.id && approver.isApprovalForAll === 'false') return approver
      if (approver.userId === $AuthUser.id && approver.isApprovalForAll === 'true') return approver
      if (approver.currentApprover === 'active' && approver.isApprovalForAll === 'true') return approver
    }

    return null
  }),

  submittedBy: (state) => state.myApprovalRequests.find((request) => (
    request.requestId === router.currentRoute.params.id
  ))
};
