import state from './moduleOrgState';
import mutations from './moduleOrgMutations';
import actions from './moduleOrgActions';
import getters from './moduleOrgGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
