import lodash from "lodash";

export default {
  UPDATE_OUTLET_LIST(state, payload) {
    state.listOfOutlets = payload;
  },
  ORG_STRUCTURE_CURRENT_NODE(state, payload) {
    state.orgStructure.selectedNode = payload;
  },
  UPDATE_DEFAULT_ROLESET(state, payload) {
    state.defaultRoles = payload;
  },
  UPDATE_ORG_PAYSCHEDULE(state, payload) {
    state.payScheduleSetting = lodash.cloneDeep(payload);
  },
  UPDATE_WORKDAYS_PER_WEEK(state) {
    state.payScheduleSetting.payrollSchedule.daysPerweek = state.payScheduleSetting.payrollSchedule.weekDays.filter(
      val => val.state === true
    ).length;
  },

  ORG_FUNCTIONS(state, payload) {
    state.functions = payload;
  },
  ORG_GET_FUNCTIONS(state, payload) {
    state.getAllFunctions = payload;
  },
  ORG_GET_DESIGNATION(state, payload) {
    state.getAllDesignations = payload;
  },
  ORG_STATS(state, payload) {
    state.getOrgStats = payload;
  },
  GET_ORG_PROFILE(state, payload) {
    state.orgProfile = payload;
  },
  SET_ROOT_USER(state, payload) {
    state.rootUser = payload;
  },
  GET_ALL_PFA(state, payload) {
    state.getAllPfa = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_SECURITY_QUESTION_STATUS(state, payload) {
    state.securityQuestionStatus = payload;
  },
  SET_TWO_FACTOR_STATUS(state, payload) {
    state.twoFactorStatus = payload;
  },
  SET_ACTIVE_SECURITY_QUESTIONS(state, payload) {
    state.activeSecurityQuestions = payload;
  },
  SET_SECURITY_QUESTIONS(state, payload) {
    state.securityQuestions = payload;
  },
  SET_ALL_ORG_USERS(state, payload) {
    state.allOrgUsers = payload;
  },
  SET_PAYSCHEDULE(state, payload) {
    state.paySchedule = payload;
  }
};
