import axios from "axios";

// Org Repository

const $API_GATEWAY_HUMANAR = `${process.env.VUE_APP_API_GATEWAY_ENDPOINT}/hr/`;
const $API_GATEWAY_CLOUDENLY = `${process.env.VUE_APP_CLOUDENLY_ENDPOINT}/hr/`;
const $API_GATEWAY_AAA = `${process.env.VUE_APP_API_GATEWAY_ENDPOINT}/`;
const $API_GATEWAY_PERFORMANCE = `${process.env.VUE_APP_API_PERFORMANCE_ENDPOINT}/humanar-performance/`;
const $NOTIFICATION = `${process.env.VUE_APP_NOTIFICATION}`;
const $API_GATEWAY_APPROVAL = `${process.env.VUE_APP_API_GATEWAY_ENDPOINT}/approvals/v1`;
const $PAYMENT_LIVE = `${process.env.VUE_APP_PAYMENT_LIVE_ENDPOINT}/payments/v1`;

const urlParams = new URLSearchParams(window.location.search);
const linchpin = urlParams.get("linchpin");
const domain = urlParams.get('domain')

export const $simpleAuthHeader = {
  Accept: "application/json",
  authorization:
    typeof linchpin === "string" ? linchpin : localStorage.getItem("token")
};

let $orgId = "";
let authUser = "";
let orgData = ""
if (JSON.parse(localStorage.getItem("linchpin")) !== null) {
  $orgId = JSON.parse(localStorage.getItem("linchpin")).org.id;
  authUser = JSON.parse(localStorage.getItem("linchpin")).user;
  orgData = JSON.parse(localStorage.getItem("linchpin")).org;
}
export const OrgId = $orgId;
export const $AuthUser = authUser;

export const AxiosHumanar = axios.create({
  baseURL: $API_GATEWAY_HUMANAR,
  headers: $simpleAuthHeader
});
export const AxiosNotification = axios.create({
  baseURL: $NOTIFICATION,
  headers: $simpleAuthHeader
});
export const AxiosPerformance = axios.create({
  baseURL: $API_GATEWAY_PERFORMANCE,
  headers: $simpleAuthHeader
});
export const AxiosAAA = axios.create({
  baseURL: $API_GATEWAY_AAA,
  headers: $simpleAuthHeader
});
export const AxiosCloudenly = axios.create({
  baseURL: $API_GATEWAY_CLOUDENLY,
  headers: $simpleAuthHeader
});
export const AxiosAPP = axios.create({
  baseURL: $API_GATEWAY_APPROVAL,
  headers: $simpleAuthHeader
});

export const AxiosPaymentLive = axios.create({
  baseURL: $PAYMENT_LIVE,
  headers: $simpleAuthHeader
});
AxiosHumanar.interceptors.response.use(
  response => response,
  error => {
    const {response} = error
    if (response.status === 401 && response.data === "Unauthorized") {
      // if you ever get an unauthorized, logout the user
      window.location.assign(`${process.env.VUE_APP_CLOUDENLY}sign-in/?domain=${domain || orgData.alias}`);
    }
    return Promise.reject(response?.data?.message || "An error occurred");
  }
);
AxiosCloudenly.interceptors.response.use(
  response => response,
  error => {
    const {response} = error
    if (response.status === 401 && response.data === "Unauthorized") {
      // if you ever get an unauthorized, logout the user
      window.location.assign(process.env.VUE_APP_CLOUDENLY);
    }
    return Promise.reject(response?.data?.message || "An error occurred");
  }
);
AxiosAAA.interceptors.response.use(
  response => response,
  error => {
    const {response} = error
    if (response.status === 401 && response.data === "Unauthorized") {
      // if you ever get an unauthorized, logout the user
      window.location.assign(`${process.env.VUE_APP_CLOUDENLY}sign-in/?domain=${domain || orgData.alias}`);
    }
    return Promise.reject(response.data?.msg || response.data.message);
  }
);
