import orgDesignRoutes from './orgDesignRoutes';
import performanceRoutes from './performanceRoutes';
import locationRoutes from './locationRoutes';
import employeeRoutes from './employeeRoutes';
import settingsRoutes from './settingsRoutes';
import payrollRoutes from './payrollRoutes';
import approvalRoutes from './approvalRoutes';
import benefitsRoutes from './benefitsRoutes';
import compensationRoutes from './compensationRoutes';
import analyticsRoutes from './analyticsRoutes';
import timeAttendanceRoutes from './timeAttendanceRoutes';
import documentRoutes from './documentRoutes';

export default [
  ...orgDesignRoutes,
  ...performanceRoutes,
  ...employeeRoutes,
  ...settingsRoutes,
  ...locationRoutes,
  ...payrollRoutes,
  ...approvalRoutes,
  ...benefitsRoutes,
  ...compensationRoutes,
  ...analyticsRoutes,
  ...timeAttendanceRoutes,
  ...documentRoutes
];
