import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/payroll/payroll-dashboard",
    name: "Payroll Dashboard",
    component: () => import("@/modules/Admin/PayrollModule"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/variable-earnings",
    name: "VariableEarnings",
    component: () =>
      import("@/modules/Admin/PayrollModule/VariableEarnings/index"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/variable-earnings/view-earnings/:id",
    name: "ViewVariableEarnings",
    component: () =>
      import("@/modules/Admin/PayrollModule/VariableEarnings/ViewEarnings"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/employee-payroll",
    name: "Employee Payroll",
    component: () => import("@/modules/Admin/PayrollModule/Employees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/employee-payroll/employee-information/:id",
    name: "viewEmployee",
    component: () => import("@/modules/Admin/PayrollModule/viewEmployee"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/employee-payroll/payslip/:id",
    name: "viewPaySlip",
    component: () => import("@/modules/Admin/PayrollModule/viewPayslip"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/paye",
    name: "PAYE",
    component: () =>
      import("@/modules/Admin/PayrollModule/Paye/index"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/paye/view-tin/:id",
    name: "ViewTIN",
    component: () =>
      import("@/modules/Admin/PayrollModule/Paye/ViewTin"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/paye/payCycle/:id",
    name: "PayCycle",
    component: () =>
      import("@/modules/Admin/PayrollModule/Paye/PayCycle"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/paye/view-employees/:id/:idNo",
    name: "ViewEmployeesPAYE",
    component: () =>
      import("@/modules/Admin/PayrollModule/Paye/ViewEmployees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun",
    name: "Pay Runs",
    component: () => import("@/modules/Admin/PayrollModule/Payrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/pension",
    name: "Pension",
    component: () =>
      import("@/modules/Admin/PayrollModule/Pensions/index"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/new",
    name: "New Payrun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/NewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/edit/:id",
    name: "Edit Payrun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/ViewDraftPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/paid/cycle",
    name: "ViewPayrunCycle",
    component: () =>
      import("@/modules/Admin/PayrollModule/Payrun/Paid/payCycle"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/pension/view/:id",
    name: "ViewPension",
    component: () =>
      import("@/modules/Admin/PayrollModule/Pensions/ViewPension"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/pension/view-employees/:id/:idNo",
    name: "ViewEmployees",
    component: () =>
      import("@/modules/Admin/PayrollModule/Pensions/ViewEmployees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/view/:id",
    name: "View Payrun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/ViewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/pension/pensionDetails/:id",
    name: "Pension Details",
    component: () =>
      import(
        "@/modules/Admin/PayrollModule/Pensions/PensionDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/nhf",
    name: "NHF",
    component: () => import("@/modules/Admin/PayrollModule/Nhf"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/nhf/employees/:id",
    name: "ViewEmployeesNHF",
    component: () => import("@/modules/Admin/PayrollModule/Nhf/ViewEmployees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/backpay",
    name: "BackPay",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/Backpay"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/process-backpay",
    name: "BackPayPayRun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/Backpay/NewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/backpay/view-employees/:id",
    name: "viewBackpay",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/Backpay/ViewEmployees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/payrun/backpay/view/:id",
    name: "View BackPay Review",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/Backpay/ViewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/terminalPay",
    name: "terminalPay",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/TerminalPay"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/terminalPay/viewEmployees/:id",
    name: "viewTerminalEmployees",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/TerminalPay/ViewEmployees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/terminalPay/viewPayrun/:id",
    name: "viewDraftTerminalPayrun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/TerminalPay/ViewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/thirteenthMonth",
    name: "13thMonth",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/ThirteenthMonth"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/thirteenthMonth/payrun/:id",
    name: "13thMonthPayrun",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/ThirteenthMonth/ViewPayrun"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/thirteenthMonth/employees",
    name: "ViewThirteenthEmployees",
    component: () => import("@/modules/Admin/PayrollModule/Payrun/ThirteenthMonth/ViewEmployees"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
];
