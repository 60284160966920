export default [
  {
    path: "/ess/advance-loans",
    name: "EssAdvanceLoans",
    component: () => import("@/modules/Ess/BenefitsModule/AdvanceLoans"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/advance-loans/new-advance",
    name: "EssNewAdvance",
    component: () =>
      import("@/modules/Ess/BenefitsModule/AdvanceLoans/NewAdvance"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/advance-loans/new-loan",
    name: "EssNewLoan",
    component: () =>
      import("@/modules/Ess/BenefitsModule/AdvanceLoans/NewLoan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/leave-old",
    name: "EssLeaveOld",
    component: () => import("@/modules/Ess/BenefitsModule/Leave_old"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management",
    name: "EssLeaveManagement",
    component: () => import("@/modules/Ess/BenefitsModule/Leave"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/annual-leave-plan/create-plan",
    name: "CreateAnnualLeavePlan",
    component: () => import("@/modules/Ess/BenefitsModule/Leave/CreateAnnualLeavePlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/annual-leave-plan/:mode/:id",
    name: "EditAnnualLeavePlan",
    component: () => import("@/modules/Ess/BenefitsModule/Leave/CreateAnnualLeavePlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/overtime",
    name: "EssOvertime",
    component: () => import("@/modules/Ess/BenefitsModule/Overtime"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
];
