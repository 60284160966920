import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import moduleAuthentication from './authentication';
import moduleOrg from './org';
import addemployee from './addemployee/addemployee';
import moduleEmployee from './employee';
import moduleLocation from './location';
import modulePerformance from './performance';
import modulePermission from './permission';
import modulePension from './pension';
import moduleCompensation from './compensation';
import moduleApproval from './approval';

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    authentication: moduleAuthentication,
    org: moduleOrg,
    addemployee,
    location: moduleLocation,
    performance: modulePerformance,
    employee: moduleEmployee,
    permission: modulePermission,
    pension: modulePension,
    compensation: moduleCompensation,
    approval: moduleApproval
  },
  strict: process.env.NODE_ENV !== 'production',
});
