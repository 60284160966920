import state from './moduleApprovalState'
import mutations from './moduleApprovalMutations'
import actions from './moduleApprovalActions'
import getters from './moduleApprovalGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
