export default [
  {
    path: "/ess/time-attendance",
    name: "EssTimeAttendance",
    component: () => import("@/modules/Ess/TimeAttendance"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/time-attendance/employee-schedule/:id",
    name: "EssEmployeeSchedule",
    component: () => import("@/modules/Ess/TimeAttendance/EmployeeSchedule"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  }
];
