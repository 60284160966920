import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "locationAndTax",
    name: "LocationAndTax",
    component: () => import("@/modules/Admin/LocationAndTaxModule"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  }
  // {
  //   path: "locationAndTax/location",
  //   name: "Location",
  //   component: () => import("@/views/pages/admin/locationAndTax/location.vue"),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true
  //   }
  // },
  // {
  //   path: "locationAndTax/location/:id",
  //   name: "Location",
  //   component: () => import("@/views/pages/admin/locationAndTax/location.vue"),
  //   meta: {
  //     authRequired: true,
  //     adminRequire: true
  //   }
  // },
];
