/* eslint-disable import/no-cycle */
import API from "@/services/apiFactory"
import router from "../../router"

export default {
  setIsLoading({ commit }, payload){
    commit("SET_ISLOADING", payload)
  },

  async getApprovalProgress({ commit }, payload){
    await API.$_getApprovalProgress(payload).then(({ data }) => {
      commit("SET_APPROVAL_LIST", data.data)
    })
  },

  async getMyApprovalRequests({ commit }, payload){
    if (router.currentRoute.query.slug === 'history') {
      const queryParams = {
        ...payload,
        userId:  payload.userId || this.state.linchpin?.user?.id,
        params: { platform: router.currentRoute.meta.adminRequire ? 'admin' : 'ess' }
      }

      await API.$_getOrgApprovalHistory(queryParams).then(({ data }) => {
        commit("SET_MY_APPROVAL_REQUESTS", data.approvals)
      })
    } else {
      await API.$_getMyApprovalRequests(payload).then(({ data }) => {
        commit("SET_MY_APPROVAL_REQUESTS", data.approvals)
      })
    }
  },
}
