export default {
  payrollSchedule(state) {
    return state.payScheduleSetting.payrollSchedule;
  },
  payrunSchedule(state) {
    return state.payScheduleSetting.payrunSchedule;
  },
  displayAllFunctions(state) {
    return state.getAllFunctions.map((item) => ({
      id: item.id,
      name: item.name,
      value: item.value,
    }));
  },
  orgProfile({ orgProfile }) {
    return {
      id: orgProfile.id,
      logoUrl: orgProfile.logoUrl,
      name: orgProfile.name,
      alias: orgProfile.alias,
      type: orgProfile.type,
      rcNumber: orgProfile.rcNumber,
      email: orgProfile.email,
      dialCode: orgProfile.dialCode,
      phoneNo: orgProfile.phoneNo,
      website: orgProfile.website,
      industryCategory: orgProfile.industryCategory ? orgProfile.industryCategory.trim() : null,
      businessCoverage: orgProfile.businessCoverage,
      timeZone: orgProfile.timeZone,
      language: orgProfile.language,
      facebook: orgProfile.facebook,
      twitter: orgProfile.twitter,
      instagram: orgProfile.instagram,
      linkedIn: orgProfile.linkedIn,
    };
  },
  orgSpecializations({ orgProfile }) {
    return orgProfile.specializations.map(({ id, name }) => ({
      orgId: orgProfile.id,
      subIndustryId: id,
      name,
    }));
  },
  rootUser({ rootUser }) {
    return {
      id: rootUser.id,
      photo: rootUser.photo,
      fname: rootUser.fname,
      mname: rootUser.mname,
      lname: rootUser.lname,
      gender: rootUser.gender,
      email: rootUser.email,
      phoneNo: rootUser.phoneNo,
    };
  },
  allOrgUsers({ allOrgUsers }) {
    return allOrgUsers.map((item) => ({
      id: item.id,
      name: `${item.fname} ${item.lname}`,
    }));
  },
};
