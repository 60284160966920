import apiFactory from "@/services/apiFactory";

export default {
  async getPermissions({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getPermissions();
      commit("SET_PERMISSIONS", data.categories);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getLevelPermissions({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_getLevelPermissions(payload);

      commit("SET_LEVEL_PERMISSIONS", data.categories);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async savePermissions({ commit, dispatch }, payload) {
    try {
      // commit("SET_LOADING", true);
      const { data } = await apiFactory.$_savePermissions(payload);
      await dispatch("getPermissions");

      commit(
        "SET_TOAST",
        { text: data.message, color: "#009432" },
        { root: true }
      );
    } catch (err) {
      commit(
        "SET_TOAST",
        { text: "An Error Occured", color: "#EA2027" },
        { root: true }
      );
      throw new Error(err.response);
    }
  },
  async saveLevelPermissions({ commit, dispatch }, payload) {
    try {
      // commit("SET_LOADING", true);
      const { data } = await apiFactory.$_saveLevelPermissions(payload);
      await dispatch("getLevelPermissions", payload.permissions[0].levelId);

      commit(
        "SET_TOAST",
        { text: data.message, color: "#009432" },
        { root: true }
      );
    } catch (err) {
      commit(
        "SET_TOAST",
        { text: "An Error Occured", color: "#EA2027" },
        { root: true }
      );
      throw new Error(err.response);
    }
  },
  async getRolesModules({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_NewGetUpdateRoles(payload);
      commit("SET_MODULES", data.modules);
      commit("SET_ROLE", {
        name: data.name,
        description: data.description || ""
      });
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  },
  async getModules({ commit }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apiFactory.$_NewRolesPrivileges();
      commit("SET_MODULES", data.modules);
      commit("SET_LOADING", false);
    } catch (err) {
      throw new Error(err.response);
    }
  }
};
